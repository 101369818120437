import { reduce } from "lodash";

import { metersToNauticalMiles } from "@skydio/math";

import { distanceFromLatLng } from "utils/map";

import { getMostPreciseLocation } from "../location";

import type { FlightPlayerState, LocalAirTrafficItemState } from "state/flight_player/slice";

type Props = {
  airTrafficData: FlightPlayerState["airTrafficData"];
  vehicleAndCameraPoseLite: FlightPlayerState["telemetry"]["vehicleAndCameraPoseLite"];
  vehicleStatus: FlightPlayerState["telemetry"]["vehicleStatus"];
  trafficDisplaySettings: {
    alert: { radius: number; altitude: number };
    filter: { radius: number; altitude: number };
  };
};

/**
 * Filters the air traffic coming from the ADS-B receiver to. The filter is based on:
 * - the distance to the current drone position
 * - the altitude of the vehicle
 */
export const getLocalAirTraffic = ({
  airTrafficData,
  vehicleAndCameraPoseLite,
  vehicleStatus,
  trafficDisplaySettings,
}: Props) => {
  const droneLocation = getMostPreciseLocation(
    vehicleAndCameraPoseLite?.current,
    vehicleStatus.current
  );
  if (droneLocation == null) {
    // If the drone has not acquired a location yet return an empty array as local traffic is with respect to the current position
    return [];
  }
  return reduce<FlightPlayerState["airTrafficData"]["rawMessages"], LocalAirTrafficItemState[]>(
    airTrafficData.rawMessages,
    (result, vehicleState) => {
      const { position, altitudeM, heading, receiveTime } = vehicleState ?? {};
      if (position != null && altitudeM != null && heading != null && receiveTime != null) {
        // Compute 2D distance
        const distance = distanceFromLatLng(
          [droneLocation.lat, droneLocation.lng],
          [position.latitude * 1e-7, position.longitude * 1e-7]
        );
        // Get relative altitude to the drone
        const relativeAltitude = altitudeM - (droneLocation?.alt ?? 0); // If the drone has not acquired an altitude yet, fallback to 0
        if (
          distance <= trafficDisplaySettings.filter.radius &&
          relativeAltitude <= trafficDisplaySettings.filter.altitude
        ) {
          // Warning(Nacho): this assumes that the filter zone is always bigger than the alert area, change condition if it's not the case
          return [
            ...result,
            {
              altitudeMSL: altitudeM,
              relativeAltitude,
              heading,
              position: { lat: position.latitude * 1e-7, lng: position.longitude * 1e-7 },
              emitterType: vehicleState?.emitterType ?? 0,
              distance: metersToNauticalMiles(distance),
              alert:
                distance < trafficDisplaySettings.alert.radius &&
                Math.abs(relativeAltitude) < trafficDisplaySettings.alert.altitude,
              icaoAddress: vehicleState?.icaoAddress!,
              horizontalVelocity: vehicleState?.horizontalVelocity!,
              verticalVelocity: vehicleState?.verticalVelocity!,
            },
          ];
        }
      }
      return result;
    },
    []
  );
};
