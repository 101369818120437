import {
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
  faWarning,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Toaster as Sonner } from "sonner";

import { cn } from "../../utils/cn";

import type { ToasterProps } from "sonner";

export const Toaster = ({ className, ...props }: ToasterProps) => (
  <Sonner
    position="top-center"
    className={cn("toaster group", className)}
    richColors
    icons={{
      info: <FontAwesomeIcon icon={faInfoCircle} />,
      warning: <FontAwesomeIcon icon={faWarning} />,
      success: <FontAwesomeIcon icon={faCheckCircle} />,
      error: <FontAwesomeIcon icon={faExclamationCircle} />,
    }}
    toastOptions={{
      classNames: {
        toast: cn(
          "group",
          "toast",
          "group-[.toaster]:bg-white",
          "dark:group-[.toaster]:bg-gray-800",
          "group-[.toaster]:text-gray-850",
          "dark:group-[.toaster]:text-white",
          "group-[.toaster]:border-gray-50",
          "dark:group-[.toaster]:border-gray-700",
          "group-[.toaster]:shadow-lg",
          "group-[.toaster]:p-3",
          "group-[.toaster]:data-[type=info]:bg-blue-50",
          "group-[.toaster]:data-[type=info]:text-blue",
          "group-[.toaster]:data-[type=info]:border-blue-100",
          "group-[.toaster]:data-[type=warning]:bg-yellow-50",
          "group-[.toaster]:data-[type=warning]:text-yellow-600",
          "group-[.toaster]:data-[type=warning]:border-yellow-100",
          "group-[.toaster]:data-[type=success]:bg-green-50",
          "group-[.toaster]:data-[type=success]:text-green-600",
          "group-[.toaster]:data-[type=success]:border-green-100",
          "group-[.toaster]:data-[type=error]:bg-red-50",
          "group-[.toaster]:data-[type=error]:text-red",
          "group-[.toaster]:data-[type=error]:border-red-100",
          "dark:group-[.toaster]:data-[type=info]:bg-blue-800",
          "dark:group-[.toaster]:data-[type=info]:border-blue-700",
          "dark:group-[.toaster]:data-[type=warning]:bg-yellow-800",
          "dark:group-[.toaster]:data-[type=warning]:border-yellow-700",
          "dark:group-[.toaster]:data-[type=success]:bg-green-800",
          "dark:group-[.toaster]:data-[type=success]:border-green-700",
          "dark:group-[.toaster]:data-[type=error]:bg-red-800",
          "dark:group-[.toaster]:data-[type=error]:border-red-700"
        ),
        actionButton: cn(
          "!transition-colors",
          "group-[.toast]:!text-white",
          "group-[.toast]:!bg-blue",
          "group-[.toast]:hover:!bg-blue-400",
          "dark:group-[.toast]:!bg-gray-800",
          "dark:group-[.toast]:!border-gray-700",
          "dark:group-[.toast]:!border",
          "dark:group-[.toast]:!border-solid",
          "dark:group-[.toast]:hover:!bg-gray-700"
        ),
        cancelButton: cn(
          "group-[.toast]:!bg-gray-100",
          "group-[.toast]:!text-gray-850",
          "dark:group-[.toast]:!bg-gray-700",
          "dark:group-[.toast]:!text-gray-100"
        ),
        icon: "group-[.toast]:text-base",
      },
    }}
    {...props}
  />
);

export type { ToasterProps };
