import { createSlice } from "@reduxjs/toolkit";

import { UiVariableKeys } from "@skydio/pbtypes/pbtypes/gen/skills/ui_variable_keys_pb";
import { ZoomBlocker } from "@skydio/pbtypes/pbtypes/gen/subject_stream_switcher/zoom_blocker_pb";

import { teleopActions } from "state/teleop/slice";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { SubjectStreamSwitcherStatus } from "@skydio/channels/src/subject_stream_switcher_status_pb";
import type { ValueType } from "@skydio/core";
import type { UiFeedback } from "@skydio/pbtypes/pbtypes/gen/skills/ui_feedback_pb";

export interface UIFeedbackValue {
  min: number;
  max: number;
  desired: number;
  actual: number;
}

export interface ARState {
  range?: UIFeedbackValue;
  elevation?: UIFeedbackValue;
  zoomLevel?: number;
  digitalZoomAvailable?: boolean;
  zoomBlockers?: string[];
}

const initialState = {} as ARState;

const getKeyFromUIFeedback = (
  feedback: UiFeedback.AsObject,
  key: ValueType<UiVariableKeys.EnumMap>
) => {
  const min = feedback.min?.varsList.find(uiVariable => uiVariable.key === key)?.value;
  const max = feedback.max?.varsList.find(uiVariable => uiVariable.key === key)?.value;
  const desired = feedback.desired?.varsList.find(uiVariable => uiVariable.key === key)?.value;
  const actual = feedback.actual?.varsList.find(uiVariable => uiVariable.key === key)?.value;
  if (min !== undefined && max !== undefined && desired !== undefined && actual !== undefined) {
    return { min, max, desired, actual };
  }
  return undefined;
};

const { reducer, actions: uiFeedbackActions } = createSlice({
  name: "ui_feedback",
  initialState,
  reducers: {
    handleUIFeedback(state, { payload }: PayloadAction<UiFeedback.AsObject>) {
      state.range = getKeyFromUIFeedback(payload, UiVariableKeys.Enum.RANGE);
      state.elevation = getKeyFromUIFeedback(payload, UiVariableKeys.Enum.ELEVATION);
    },
    handleSubjectSwitcher(state, { payload }: PayloadAction<SubjectStreamSwitcherStatus.AsObject>) {
      state.zoomLevel = payload.zoomLevel;
      state.digitalZoomAvailable = payload.digitalZoomAvailable;
      state.zoomBlockers = payload.zoomBlockersList
        .map(
          blocker =>
            Object.entries(ZoomBlocker.Enum).find(([_key, value]) => value === blocker)?.[0]
        )
        .filter(Boolean) as string[];
    },
  },
  extraReducers: builder =>
    builder.addCase(teleopActions.updateTeleopSessionId, () => {
      // Clear state when we get a new teleop sesion Id
      return initialState;
    }),
});

export default reducer;
export { uiFeedbackActions };
