import { Children, cloneElement, forwardRef, useMemo } from "react";
import { mergeProps, useButton } from "react-aria";

import { pickChildren } from "../../utils/children";
import { Button } from "../button/Button";
import { usePopoverContext } from "./context";

import type { ReactElement, ReactNode, Ref } from "react";

export interface PopoverTriggerProps {
  children?: ReactNode;
}

/**
 * PopoverTrigger opens the popover's content. It must be an interactive element
 * such as `button` or `a`.
 */
export const PopoverTrigger = forwardRef<HTMLButtonElement, PopoverTriggerProps>(
  ({ children }, _) => {
    const { triggerRef, getTriggerProps } = usePopoverContext();

    // force a single child
    const child = useMemo<any>(() => {
      if (typeof children === "string") return <p>{children}</p>;

      return Children.only(children) as ReactElement & {
        ref?: Ref<any>;
      };
    }, [children]);

    const { onPress, ...restProps } = useMemo(() => {
      return mergeProps(child.props, getTriggerProps(child.ref));
    }, [getTriggerProps, child.props, child.ref]);

    // validates if contains a Rivet Button as a child
    const [, triggerChildren] = pickChildren(children, Button);

    const { buttonProps } = useButton({ onPress }, triggerRef);

    const hasRivetButton = useMemo<boolean>(() => {
      return triggerChildren?.[0] !== undefined;
    }, [triggerChildren]);

    // If it's not a Rivet button give it a fresh set of react-aria button props
    // otherwise just the onPress handler
    return cloneElement(child, mergeProps(restProps, hasRivetButton ? { onPress } : buttonProps));
  }
);

PopoverTrigger.displayName = "Popover.Trigger";
