import { findKey } from "lodash";

import device_alert_pb from "@skydio/pbtypes/pbtypes/vehicle/alerting/alert_pb";

import type { ValueType } from "@skydio/core";

export const alertTypeEnum = device_alert_pb.DeviceAlert.AlertTypeEnum;

export const getAlertTypeKey = (
  alertType: ValueType<device_alert_pb.DeviceAlert.AlertTypeEnumMap>
) => findKey(device_alert_pb.DeviceAlert.AlertTypeEnum, key => key === alertType);
