import { computeGPSLocationFrom3DCartesianPosition, convertENUTupleToEUSTuple } from "@skydio/math";

import type { GPSPoint } from "@skydio/math";
import type { FlightPlayerState } from "state/flight_player/slice";

/**
 * Gets the most precise GPS location of the vehicle from the telemetry.
 *
 * It returns the pose lite position (hybrid) if available falling back to the vehicle GPS one.
 * No vision location is provided here as this outputs a GPSPoint
 */
export const getMostPreciseLocation = (
  currentPoseLite: FlightPlayerState["telemetry"]["vehicleAndCameraPoseLite"]["current"],
  currentVehicleStatus: FlightPlayerState["telemetry"]["vehicleStatus"]["current"]
): GPSPoint | undefined => {
  const worldOrigin = currentPoseLite?.worldGpsOrigin;
  const currentDroneLocationCartesian = currentPoseLite?.worldPsVehicle?.position?.dataList;
  if (worldOrigin && currentDroneLocationCartesian) {
    const droneGpsArray = computeGPSLocationFrom3DCartesianPosition({
      threeJSPosition: convertENUTupleToEUSTuple(
        currentDroneLocationCartesian as [number, number, number]
      ),
      sceneOrigin: [worldOrigin.longitude, worldOrigin.latitude],
    });
    return { lng: droneGpsArray[0], lat: droneGpsArray[1], alt: droneGpsArray[2] };
  }
  const vehicleGpsLat = currentVehicleStatus?.vehicleGps?.latitude;
  const vehicleGpsLon = currentVehicleStatus?.vehicleGps?.longitude;
  const vehicleGpsAlt = currentVehicleStatus?.vehicleGps?.altitude ?? 0;
  if (vehicleGpsLat && vehicleGpsLon) {
    return { lng: vehicleGpsLon, lat: vehicleGpsLat, alt: vehicleGpsAlt };
  }
};
