/**
 * NOTE(sam): The main input logic is in this hook instead of the Input component itself so we can
 * reuse it for a textarea component.
 */

import { useControlledState } from "@react-stately/utils";
import { useCallback, useMemo, useState } from "react";
import {
  chain,
  mergeProps,
  useFocusRing,
  useFocusWithin,
  useHover,
  usePress,
  useTextField,
} from "react-aria";
import { tv } from "tailwind-variants";

import { useDomRef } from "../../hooks/useDomRef";
import { cn } from "../../utils/cn";
import { filterDOMProps } from "../../utils/filterDomProps";
import { NOOP_CALLBACK } from "../../utils/misc";
import { formLabel } from "../../utils/tailwindClasses";

import type { FocusEvent, HTMLAttributes, MouseEvent, Ref } from "react";
import type { AriaTextFieldOptions, AriaTextFieldProps } from "react-aria";
import type { VariantProps } from "tailwind-variants";
import type { DOMAttributes, SlotsToClasses } from "../../utils/types";

// eslint-disable-next-line tailwindcss/enforces-negative-arbitrary-values
const input = tv({
  slots: {
    base: "group relative flex flex-col",
    label: [...formLabel],
    mainWrapper: "flex h-full flex-col",
    inputWrapper: [
      "relative",
      "w-full",
      "inline-flex",
      "tap-highlight-transparent",
      "flex-row",
      "items-center",
      "px-3",
      "gap-3",
      "border",
      "border-solid",
      "border-gray-150",
      "dark:border-gray-700",
      "bg-white",
      "dark:bg-gray-800",
      "rounded",
      "data-[hover=true]:border-gray-300",
      "dark:data-[hover=true]:border-gray-600",
      "group-data-[focus=true]:border-blue-400",
      "dark:group-data-[focus=true]:border-blue-700",
    ],
    innerWrapper: "box-border inline-flex size-full items-center",
    input: [
      "h-full",
      "w-full",
      "!border-none",
      "bg-transparent",
      "font-normal",
      "!outline-none",
      "placeholder:text-gray-300",
      "focus-visible:outline-none",
      "dark:text-white",
      "dark:caret-white",
      "data-[has-start-content=true]:ps-1.5",
      "data-[has-end-content=true]:pe-1.5",
    ],
    clearButton: [
      "p-2",
      "-m-2",
      "z-10",
      "text-xs",
      "hidden",
      "absolute",
      "right-3",
      "appearance-none",
      "outline-none",
      "select-none",
      "opacity-0",
      "hover:!opacity-100",
      "cursor-pointer",
      "active:!opacity-70",
      "rounded-full",
      "dark:text-gray-500",
      // focus ring
      "outline-none",
      "data-[focus-visible=true]:z-10",
      "data-[focus-visible=true]:outline-2",
      "data-[focus-visible=true]:outline-focus",
      "data-[focus-visible=true]:outline-offset-2",
    ],
    helperWrapper: "relative hidden flex-col gap-1.5 p-1 group-data-[has-helper=true]:flex",
    description: "text-xs text-gray-400",
    errorMessage: "text-xs text-red",
    startContent: "-ml-1 mr-1 text-gray-500",
  },
  variants: {
    size: {
      sm: {
        label: "text-xs",
        inputWrapper: "h-7 min-h-7 px-2",
        input: "text-sm",
        clearButton: "text-xs",
      },
      md: {
        inputWrapper: "h-8 min-h-8",
        input: "text-sm",
        clearButton: "text-sm",
      },
      lg: {
        inputWrapper: "h-10 min-h-10",
        input: "text-base",
        clearButton: "text-sm",
      },
    },
    labelPlacement: {
      top: {
        label: [
          "pe-2",
          "max-w-full",
          "text-ellipsis",
          "overflow-hidden",
          "group-data-[filled-within=true]:text-gray-900",
          "dark:group-data-[filled-within=true]:text-gray-100",
          "group-data-[filled-within=true]:pointer-events-auto",
        ],
      },
      left: {
        base: "flex-row flex-nowrap items-center data-[has-helper=true]:items-start",
        inputWrapper: "flex-1",
        label: "relative pr-2 text-gray-900 dark:text-gray-100",
      },
    },
    fullWidth: {
      true: {
        base: "w-full",
      },
      false: {
        mainWrapper: "min-w-64",
      },
    },
    isClearable: {
      true: {
        input: "peer pr-6",
        clearButton: "peer-data-[filled=true]:block peer-data-[filled=true]:opacity-70",
      },
    },
    isDisabled: {
      true: {
        base: "pointer-events-none opacity-60",
        inputWrapper: "pointer-events-none",
        label: "pointer-events-none",
      },
    },
    isInvalid: {
      true: {
        label: "!text-red",
        input: "!placeholder:text-red !text-red",
        inputWrapper: "!border-red group-data-[focus=true]:border-red",
      },
    },
    isRequired: {
      true: {
        label: "after:ml-0.5 after:text-red after:content-['*']",
      },
    },
    isMultiline: {
      true: {
        label: "relative",
        inputWrapper: "!h-auto py-2",
        innerWrapper: "items-start group-data-[has-label=true]:items-start",
        input: "resize-none data-[hide-scroll=true]:scrollbar-hide",
      },
    },
    disableAnimation: {
      true: {
        input: "transition-none",
        inputWrapper: "transition-none",
        label: "transition-none",
      },
      false: {
        inputWrapper: "transition-background !duration-150 motion-reduce:transition-none",
        label: [
          "will-change-auto",
          "origin-top-left",
          "!duration-200",
          "!ease-out",
          "motion-reduce:transition-none",
          "transition-[transform,color,left,opacity]",
        ],
        clearButton: ["transition-opacity", "motion-reduce:transition-none"],
      },
    },
    hasOnlyErrorMessage: {
      true: {
        // Don't want error message to take up extra space since it will appear and disappear,
        // causing the layout to shift. Description is generally either always there or never there
        // so it's fine to take up space.
        helperWrapper: "absolute bottom-0 translate-y-full",
      },
    },
  },
  compoundVariants: [
    {
      labelPlacement: "top",
      isMultiline: false,
      class: {
        base: "group relative justify-end",
        label: [
          "pb-0",
          "z-20",
          "top-1/2",
          "-translate-y-1/2",
          "group-data-[filled-within=true]:left-0",
        ],
      },
    },
    // top & size
    {
      labelPlacement: "top",
      size: "sm",
      isMultiline: false,
      class: {
        label: [
          "left-2",
          "text-xs",
          "group-data-[filled-within=true]:-translate-y-[calc(100%_+_theme(fontSize.xs)/2_+_16px)]",
        ],
        base: "data-[has-label=true]:mt-[calc(theme(fontSize.sm)_+_8px)]",
      },
    },
    {
      labelPlacement: "top",
      size: "md",
      isMultiline: false,
      class: {
        label: [
          "left-3",
          "text-sm",
          "group-data-[filled-within=true]:-translate-y-[calc(100%_+_theme(fontSize.xs)/2_+_16px)]",
        ],
        base: "data-[has-label=true]:mt-[calc(theme(fontSize.sm)_+_10px)]",
      },
    },
    {
      labelPlacement: "top",
      size: "lg",
      isMultiline: false,
      class: {
        label: [
          "left-3",
          "text-medium",
          "group-data-[filled-within=true]:-translate-y-[calc(100%_+_theme(fontSize.sm)/2_+_24px)]",
        ],
        base: "data-[has-label=true]:mt-[calc(theme(fontSize.sm)_+_12px)]",
      },
    },
    // left & size & hasHelper
    {
      labelPlacement: "left",
      size: "sm",
      class: {
        label: "group-data-[has-helper=true]:pt-2",
      },
    },
    {
      labelPlacement: "left",
      size: "md",
      class: {
        label: "group-data-[has-helper=true]:pt-3",
      },
    },
    {
      labelPlacement: "left",
      size: "lg",
      class: {
        label: "group-data-[has-helper=true]:pt-4",
      },
    },
    // isMultiline & labelPlacement="top"
    {
      labelPlacement: "top",
      isMultiline: true,
      class: {
        label: "pb-1.5 pl-px", // pl-px to align with the input box, as the label is now in mainWrapper
      },
    },
    // isMultiline & !disableAnimation
    {
      isMultiline: true,
      disableAnimation: false,
      class: {
        input: "transition-height !duration-100 motion-reduce:transition-none",
      },
    },
  ],
  defaultVariants: {
    size: "md",
    fullWidth: false,
    labelPlacement: "top",
    isDisabled: false,
    isMultiline: false,
    disableAnimation: false,
  },
});

export type InputVariantProps = VariantProps<typeof input>;
type InputSlots = keyof ReturnType<typeof input>;

export interface Props<T extends HTMLInputElement | HTMLTextAreaElement = HTMLInputElement>
  extends Omit<HTMLAttributes<HTMLInputElement>, keyof InputVariantProps> {
  /**
   * Ref to the DOM node.
   */
  ref?: Ref<T>;
  /**
   * Ref to the container DOM node.
   */
  baseRef?: Ref<HTMLDivElement>;
  /**
   * Ref to the input wrapper DOM node.
   * This is the element that wraps the input label and the innerWrapper when the labelPlacement="inside"
   * and the input has start/end content.
   */
  wrapperRef?: Ref<HTMLDivElement>;
  /**
   * Ref to the input inner wrapper DOM node.
   * This is the element that wraps the input and the start/end content when passed.
   */
  innerWrapperRef?: Ref<HTMLDivElement>;
  /**
   * Element to be rendered in the left side of the input.
   */
  startContent?: React.ReactNode;
  /**
   * Element to be rendered in the right side of the input.
   * if you pass this prop and the `onClear` prop, the passed element
   * will have the clear button props and it will be rendered instead of the
   * default clear button.
   */
  endContent?: React.ReactNode;
  /**
   * Classname or List of classes to change the classNames of the element.
   * if `className` is passed, it will be added to the base slot.
   *
   * @example
   * ```ts
   * <Input classNames={{
   *    base:"base-classes",
   *    label: "label-classes",
   *    mainWrapper: "main-wrapper-classes",
   *    inputWrapper: "input-wrapper-classes",
   *    innerWrapper: "inner-wrapper-classes",
   *    input: "input-classes",
   *    clearButton: "clear-button-classes",
   *    helperWrapper: "helper-wrapper-classes",
   *    description: "description-classes",
   *    errorMessage: "error-message-classes",
   * }} />
   * ```
   */
  classNames?: SlotsToClasses<InputSlots>;
  /**
   * Callback fired when the value is cleared.
   * if you pass this prop, the clear button will be shown.
   */
  onClear?: () => void;
  /**
   * React aria onChange event.
   */
  onValueChange?: (value: string) => void;

  /**
   * Whether to display a search icon.
   */
  isSearch?: boolean;
  /**
   * Whether to display a loading status indicator. Should be used with `startContent` or `isSearch`.
   */
  isLoading?: boolean;
}

type AutoCapitalize = AriaTextFieldOptions<"input">["autoCapitalize"];

export type UseInputProps<T extends HTMLInputElement | HTMLTextAreaElement = HTMLInputElement> =
  Props<T> & Omit<AriaTextFieldProps, "onChange" | "onFocus"> & InputVariantProps;

export function useInput<T extends HTMLInputElement | HTMLTextAreaElement = HTMLInputElement>(
  props: UseInputProps<T>
) {
  const {
    ref,
    label,
    size,
    fullWidth,
    isMultiline,
    isDisabled,
    isSearch,
    isLoading,
    baseRef,
    wrapperRef,
    description,
    errorMessage: errorMessageProp,
    className,
    classNames,
    autoFocus,
    startContent,
    endContent,
    onClear,
    onChange,
    validationState,
    innerWrapperRef: innerWrapperRefProp,
    onValueChange = NOOP_CALLBACK,
    onFocus,
    onBlur,
    ...otherProps
  } = props;

  const handleValueChange = useCallback(
    (value: string | undefined) => {
      onValueChange(value ?? "");
    },
    [onValueChange]
  );

  const [inputValue, setInputValue] = useControlledState<string | undefined>(
    props.value,
    props.defaultValue!,
    handleValueChange
  );

  const [isFocusWithin, setFocusWithin] = useState(false);

  const isFilled = !!inputValue;
  const isFilledWithin = isFilled || isFocusWithin;
  const baseStyles = cn(classNames?.base, className, isFilled ? "is-filled" : "");

  const domRef = useDomRef<T>(ref);
  const baseDomRef = useDomRef<HTMLDivElement>(baseRef);
  const inputWrapperRef = useDomRef<HTMLDivElement>(wrapperRef);
  const innerWrapperRef = useDomRef<HTMLDivElement>(innerWrapperRefProp);

  const handleClear = useCallback(() => {
    setInputValue("");

    onClear?.();
    domRef.current?.focus();
  }, [setInputValue, onClear, domRef]);

  const {
    labelProps: ariaLabelProps,
    inputProps: ariaInputProps,
    descriptionProps: ariaDescriptionProps,
    errorMessageProps: ariaErrorMessageProps,
    ...validationResult
  } = useTextField(
    {
      ...props,
      value: inputValue,
      autoCapitalize: props.autoCapitalize as AutoCapitalize,
      "aria-label":
        props?.["aria-label"] ||
        (typeof props?.label === "string" ? props.label : "") ||
        props?.placeholder ||
        "",
      inputElementType: isMultiline ? "textarea" : "input",
      onChange: setInputValue,
      // NOTE(sam): this type is being really weird
      onBlur: props.onBlur as (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
      onFocus: props.onFocus as (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    },
    domRef
  );

  const errorMessage =
    typeof errorMessageProp === "function" ? errorMessageProp(validationResult) : errorMessageProp;

  const { isFocusVisible, isFocused, focusProps } = useFocusRing({
    autoFocus,
    isTextInput: true,
  });

  const { isHovered, hoverProps } = useHover({ isDisabled: !!props?.isDisabled });

  const { focusProps: clearFocusProps, isFocusVisible: isClearButtonFocusVisible } = useFocusRing();

  const { focusWithinProps } = useFocusWithin({
    onFocusWithinChange: setFocusWithin,
  });

  const { pressProps: clearPressProps } = usePress({
    isDisabled: !!props?.isDisabled,
    onPress: handleClear,
  });

  const isInvalid = validationState === "invalid" || props.isInvalid;

  const labelPlacement = useMemo<InputVariantProps["labelPlacement"]>(() => {
    if (!props.labelPlacement && !label) {
      return "top";
    }

    return props.labelPlacement ?? "top";
  }, [props.labelPlacement, label]);

  const isClearable = !!onClear || props.isClearable;
  const hasElements = !!label || !!description || !!errorMessage;
  const hasPlaceholder = !!props.placeholder;
  const hasLabel = !!label;
  const hasHelper = !!description || !!errorMessage;
  const isPlaceholderShown = domRef.current
    ? (!domRef.current.value || domRef.current.value === "" || !inputValue || inputValue === "") &&
      hasPlaceholder
    : false;

  const hasStartContent = !!startContent;
  const hasOnlyErrorMessage = !!errorMessage && !description;

  const slots = useMemo(() => {
    return input({
      size,
      fullWidth,
      isDisabled,
      isMultiline,
      isInvalid,
      labelPlacement,
      isClearable,
      hasOnlyErrorMessage,
    });
  }, [
    size,
    fullWidth,
    isDisabled,
    isMultiline,
    isInvalid,
    labelPlacement,
    isClearable,
    hasOnlyErrorMessage,
  ]);

  const baseProps = useMemo(() => {
    return {
      ref: baseDomRef,
      className: slots.base({ className: baseStyles }),
      "data-slot": "base",
      "data-filled": isFilled || hasPlaceholder || hasStartContent || isPlaceholderShown,

      "data-filled-within":
        isFilledWithin || hasPlaceholder || hasStartContent || isPlaceholderShown,
      "data-focus-within": isFocusWithin,
      "data-focus-visible": isFocusVisible,
      "data-readonly": props.isReadOnly,
      "data-focus": isFocused,
      "data-hover": isHovered,
      "data-required": props.isRequired,
      "data-invalid": isInvalid,
      "data-disabled": props.isDisabled,
      "data-loading": isLoading,
      "data-has-elements": hasElements,
      "data-has-helper": hasHelper,
      "data-has-label": hasLabel,
      "data-has-value": !isPlaceholderShown,
      ...focusWithinProps,
    };
  }, [
    slots,
    baseStyles,
    isFilled,
    isFocused,
    isHovered,
    isInvalid,
    isLoading,
    hasHelper,
    hasLabel,
    hasElements,
    isPlaceholderShown,
    hasStartContent,
    isFocusWithin,
    isFocusVisible,
    isFilledWithin,
    hasPlaceholder,
    focusWithinProps,
    props.isReadOnly,
    props.isRequired,
    props.isDisabled,
    baseDomRef,
  ]);

  const labelProps = useMemo(() => {
    return {
      "data-slot": "label",
      className: slots.label({ class: classNames?.label }),
      ...ariaLabelProps,
    };
  }, [slots, ariaLabelProps, classNames?.label]);

  const inputProps: DOMAttributes = useMemo(() => {
    return {
      ref: domRef,
      "data-slot": "input",
      "data-filled": isFilled,
      "data-filled-within": isFilledWithin,
      "data-has-start-content": hasStartContent,
      "data-has-end-content": !!endContent,
      className: slots.input({ class: cn(classNames?.input, !!inputValue ? "is-filled" : "") }),
      ...mergeProps(
        focusProps,
        ariaInputProps,
        filterDOMProps(otherProps, {
          enabled: true,
          labelable: true,
          omitEventNames: new Set(Object.keys(ariaInputProps)),
        })
      ),
      required: props.isRequired,
      "aria-readonly": props.isReadOnly,
      "aria-required": props.isRequired,
      onChange: chain(ariaInputProps.onChange, onChange),
    };
  }, [
    slots,
    inputValue,
    focusProps,
    ariaInputProps,
    otherProps,
    isFilled,
    isFilledWithin,
    hasStartContent,
    endContent,
    classNames?.input,
    props.isReadOnly,
    props.isRequired,
    onChange,
    domRef,
  ]);

  const inputWrapperProps: DOMAttributes = useMemo(() => {
    return {
      ref: inputWrapperRef,
      "data-slot": "input-wrapper",
      "data-hover": isHovered,
      "data-focus-visible": isFocusVisible,
      "data-focus": isFocused,
      className: slots.inputWrapper({
        class: cn(classNames?.inputWrapper, !!inputValue ? "is-filled" : ""),
      }),
      ...hoverProps,
      onClick: (e: MouseEvent<HTMLDivElement>) => {
        if (domRef.current && e.currentTarget === e.target) {
          domRef.current.focus();
        }
      },
      style: {
        cursor: "text",
        ...props.style,
      },
    };
  }, [
    slots,
    isHovered,
    isFocusVisible,
    isFocused,
    inputValue,
    classNames?.inputWrapper,
    domRef,
    hoverProps,
    inputWrapperRef,
    props.style,
  ]);

  const innerWrapperProps: DOMAttributes = useMemo(() => {
    return {
      ref: innerWrapperRef,
      "data-slot": "inner-wrapper",
      onClick: (e: MouseEvent<HTMLDivElement>) => {
        if (domRef.current && e.currentTarget === e.target) {
          domRef.current.focus();
        }
      },
      className: slots.innerWrapper({
        class: cn(classNames?.innerWrapper),
      }),
    };
  }, [slots, classNames?.innerWrapper, domRef, innerWrapperRef]);

  const mainWrapperProps: DOMAttributes = useMemo(() => {
    return {
      "data-slot": "main-wrapper",
      className: slots.mainWrapper({ class: cn(classNames?.mainWrapper) }),
    };
  }, [slots, classNames?.mainWrapper]);

  const helperWrapperProps: DOMAttributes = useMemo(() => {
    return {
      "data-slot": "helper-wrapper",
      className: slots.helperWrapper({
        class: cn(classNames?.helperWrapper),
      }),
    };
  }, [slots, classNames?.helperWrapper]);

  const descriptionProps: DOMAttributes = useMemo(() => {
    return {
      ...ariaDescriptionProps,
      "data-slot": "description",
      className: slots.description({ class: cn(classNames?.description) }),
    };
  }, [slots, ariaDescriptionProps, classNames?.description]);

  const errorMessageProps: DOMAttributes = useMemo(() => {
    return {
      ...ariaErrorMessageProps,
      "data-slot": "error-message",
      className: slots.errorMessage({ class: cn(classNames?.errorMessage) }),
    };
  }, [slots, ariaErrorMessageProps, classNames?.errorMessage]);

  const clearButtonProps: DOMAttributes = useMemo(() => {
    return {
      role: "button",
      tabIndex: 0,
      "data-slot": "clear-button",
      "data-focus-visible": isClearButtonFocusVisible,
      className: slots.clearButton({ class: cn(classNames?.clearButton) }),
      ...mergeProps(clearPressProps, clearFocusProps),
    };
  }, [slots, isClearButtonFocusVisible, clearPressProps, clearFocusProps, classNames?.clearButton]);

  const startContentProps: DOMAttributes = useMemo(() => {
    return {
      className: slots.startContent({ class: cn(classNames?.startContent) }),
    };
  }, [slots, classNames?.startContent]);

  return {
    classNames,
    domRef,
    label,
    description,
    startContent,
    endContent,
    labelPlacement,
    isClearable,
    isInvalid,
    isSearch,
    isLoading,
    hasHelper,
    hasStartContent,
    hasPlaceholder,
    errorMessage,
    baseProps,
    labelProps,
    inputProps,
    inputWrapperProps,
    innerWrapperProps,
    mainWrapperProps,
    helperWrapperProps,
    descriptionProps,
    errorMessageProps,
    clearButtonProps,
    startContentProps,
  };
}

export type UseInputReturn = ReturnType<typeof useInput>;
