/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "cloud_config";

/**
 * This file automatically generated by skymarshal.
 * DO NOT MODIFY BY HAND!!!!
 */

export interface ClientType {
}

export enum ClientType_Enum {
  UNKNOWN = 0,
  MOBILE = 2,
  VEHICLE = 3,
  BEACON = 4,
  JOYSTICKS = 5,
  DOCK = 6,
  WEB = 7,
  SKYSTICKS = 8,
  SCHEDULER = 9,
  MISSION_CONDUCTOR = 10,
  EXTERNAL_RADIO = 11,
  UNRECOGNIZED = -1,
}

export function clientType_EnumFromJSON(object: any): ClientType_Enum {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return ClientType_Enum.UNKNOWN;
    case 2:
    case "MOBILE":
      return ClientType_Enum.MOBILE;
    case 3:
    case "VEHICLE":
      return ClientType_Enum.VEHICLE;
    case 4:
    case "BEACON":
      return ClientType_Enum.BEACON;
    case 5:
    case "JOYSTICKS":
      return ClientType_Enum.JOYSTICKS;
    case 6:
    case "DOCK":
      return ClientType_Enum.DOCK;
    case 7:
    case "WEB":
      return ClientType_Enum.WEB;
    case 8:
    case "SKYSTICKS":
      return ClientType_Enum.SKYSTICKS;
    case 9:
    case "SCHEDULER":
      return ClientType_Enum.SCHEDULER;
    case 10:
    case "MISSION_CONDUCTOR":
      return ClientType_Enum.MISSION_CONDUCTOR;
    case 11:
    case "EXTERNAL_RADIO":
      return ClientType_Enum.EXTERNAL_RADIO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ClientType_Enum.UNRECOGNIZED;
  }
}

export function clientType_EnumToJSON(object: ClientType_Enum): string {
  switch (object) {
    case ClientType_Enum.UNKNOWN:
      return "UNKNOWN";
    case ClientType_Enum.MOBILE:
      return "MOBILE";
    case ClientType_Enum.VEHICLE:
      return "VEHICLE";
    case ClientType_Enum.BEACON:
      return "BEACON";
    case ClientType_Enum.JOYSTICKS:
      return "JOYSTICKS";
    case ClientType_Enum.DOCK:
      return "DOCK";
    case ClientType_Enum.WEB:
      return "WEB";
    case ClientType_Enum.SKYSTICKS:
      return "SKYSTICKS";
    case ClientType_Enum.SCHEDULER:
      return "SCHEDULER";
    case ClientType_Enum.MISSION_CONDUCTOR:
      return "MISSION_CONDUCTOR";
    case ClientType_Enum.EXTERNAL_RADIO:
      return "EXTERNAL_RADIO";
    case ClientType_Enum.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseClientType(): ClientType {
  return {};
}

export const ClientType = {
  encode(_: ClientType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClientType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClientType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ClientType {
    return {};
  },

  toJSON(_: ClientType): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ClientType>, I>>(base?: I): ClientType {
    return ClientType.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ClientType>, I>>(_: I): ClientType {
    const message = createBaseClientType();
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };
