import { findLastKey } from "lodash";

import enums_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/enums_pb";
import ucon_pb from "@skydio/pbtypes/pbtypes/vehicle/infrastructure/flight_deck/ucon_pb";
import type { ValueType } from "@skydio/core";

export const uconUploadPriorityEnum = enums_pb.UconUploadPriority.PriorityEnum;
export const deletionPolicyEnum = ucon_pb.FileDeletionPolicy.DeletionEnum;

// Both the UconUploadPriority and the DeletionPolicy enums have aliased field names
// for the "UNKNOWN" value. We want to display the other name, so we use findLastKey
// to iterate through the keys backwards.

export const getUconUploadPriorityKey = (
  priority: ValueType<enums_pb.UconUploadPriority.PriorityEnumMap>
) => findLastKey(enums_pb.UconUploadPriority.PriorityEnum, o => o === priority);

export const getDeletionPolicyKey = (
  policy: ValueType<ucon_pb.FileDeletionPolicy.DeletionEnumMap>
) => findLastKey(ucon_pb.FileDeletionPolicy.DeletionEnum, o => o === policy);
