import { createSlice } from "@reduxjs/toolkit";
import { ConnectionState } from "livekit-client-legacy";
import _ from "lodash";
import { Quaternion, Vector3 } from "three";

import { ReturnToDockInfo } from "@skydio/channels/src/return_to_dock_info_pb";
import { VehicleSettings } from "@skydio/channels/src/update_vehicle_settings_pb";
import { secondsToMilliseconds } from "@skydio/math";
import { FlightPhaseEnum } from "@skydio/pbtypes/pbtypes/gen/common/flight_phase_enum_pb";
import { GoToWaypointSource } from "@skydio/pbtypes/pbtypes/gen/skills/go_to_waypoint_source_pb";
import { ReservedWaypointKeys } from "@skydio/pbtypes/pbtypes/gen/skills/reserved_waypoint_keys_pb";
import { Auth } from "@skydio/pbtypes/pbtypes/vehicle/infrastructure/flight_deck/auth_pb";
import { NavigationMode } from "@skydio/pbtypes/pbtypes/vehicle/infrastructure/flight_deck/navigation_mode_pb";
import { InterceptOrbitPoiMode_Enum } from "@skydio/pbtypes/pbtypes/vehicle/skills/actions/orbit_poi/orbit_poi";
import { GoToWaypointState } from "@skydio/pbtypes/pbtypes/vehicle/skills/waypoints_pb";
import { toast } from "@skydio/rivet-ui";
import { convertTransPbToObject3 } from "@skydio/shared_ui/utils/math";
import { WebRTCConnectionState } from "@skydio/skybus/src/transport/types";

import { DEFAULT_FLY_TO_POINT_HEIGHT } from "components/main/flight_player/map/MapViewerWithCurrentVehicle/POI/constants";
import { teleopActions } from "state/teleop/slice";
import {
  areNumbersApproximatelyEqual,
  areVectorsApproximatelyEqual,
  kMilliTol,
} from "utils/common";

import {
  getActionForKey,
  getDirectionForKey,
  getIsMostRecentVehiclePilot,
  isInFlight,
  isInPostFlight,
  isLanding,
} from "./partialSelectors";
import { GamepadMode, MANUAL_FLIGHT_BLOCKERS } from "./types";
import {
  calculateNavHeadingPitch,
  calculateNavTCommand,
  cubicDeadzoneFunction,
  GAMEPAD_DEADZONE,
  gamepadModeMappings,
  getSpeedSettings,
  getTeleopAntMessageClassName,
  getZeroMovementInput,
  getZoomCorrection,
  isGamepadSupported,
  obstacleSafetyToObstacleMode,
  supportedGamepadMovementInputs,
  TAP_DURATION,
  toTransPb,
} from "./utils";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { VehicleStatus } from "@skydio/channels/src/ambassador_vehicle_status_pb";
import type { DeviceNodeHealth } from "@skydio/channels/src/device_node_health_summary_pb";
import type { FlightPhase as FlightPhasePb } from "@skydio/channels/src/flight_phase_pb";
import type { GimbalFlashlightStatus } from "@skydio/channels/src/gimbal_flashlight_status_pb";
import type { LandingPadTrackerSimplifiedState } from "@skydio/channels/src/landing_pad_tracker_simplified_state_pb";
import type { MotionPlannerStatus } from "@skydio/channels/src/motion_planner_status_pb";
import type { PilotResponse } from "@skydio/channels/src/pilot_response_pb";
import type { SafeLandingInfo } from "@skydio/channels/src/safe_landing_info_pb";
import type { ServerInfo } from "@skydio/channels/src/server_info_pb";
import type { SkillStatusMinimal } from "@skydio/channels/src/skill_status_pb";
import type { SpeakerMicAudioFiles } from "@skydio/channels/src/speaker_mic_list_audio_files_pb";
import type { SpeakerMicStatus } from "@skydio/channels/src/speaker_mic_status_pb";
import type { SpotlightSystemStatus } from "@skydio/channels/src/spotlight_system_status_pb";
import type { StreamCameraCalibration } from "@skydio/channels/src/stream_camera_calibration_pb";
import type { MinimalAutoRegionState } from "@skydio/channels/src/user_camera_autoregion_state_pb";
import type { ValueType } from "@skydio/core";
import type { flight_phase_t } from "@skydio/lcm/types/common/flight_phase_t";
import type { TransPb } from "@skydio/pbtypes/pbtypes/gen/body/trans_pb";
import type { CameraIntrinsics } from "@skydio/pbtypes/pbtypes/gen/calibration/camera_intrinsics_pb";
import type { BlockingPrompt } from "@skydio/pbtypes/pbtypes/gen/user_prompts/blocking_prompt_pb";
import type { PortStatusToConsumers } from "@skydio/pbtypes/pbtypes/infrastructure/drivers/attachment_manager/attachment_manager_pb";
import type { DeviceLinksSummary } from "@skydio/pbtypes/pbtypes/infrastructure/flight_connect/link_summary_pb";
import type { Flight } from "@skydio/pbtypes/pbtypes/vehicle/infrastructure/flight_deck/flight_pb";
import type {
  GoToWaypointStatus,
  GraphMotionArgs,
  LookAtMotionArgs,
  TraversalMotionArgs,
  Waypoint,
} from "@skydio/pbtypes/pbtypes/vehicle/skills/waypoints_pb";
import type {
  DroneSenseMission,
  GamepadMovementInputs,
  MouseOrientation,
  MovementDirection,
  PilotState,
  TeleopAction,
} from "./types";

const PILOT_STALE_THRESHOLD = 30;

const initialGamepadSettings = {
  mode: GamepadMode.MODE_2,
  expoLevel: { default: 0.4, value: 0.4 },
  throttleSensitivity: { default: 0.8, value: 0.8 },
  yawSensitivity: { default: 0.6, value: 0.6 },
  pitchSensitivity: { default: 0.6, value: 0.6 },
};

const initialState: PilotState = {
  vehicleConnection: {
    clientId: "",
    active: false,
    success: false,
    error: null,
    downloadBitrate: 0,
    uploadBitrate: 0,
    status: WebRTCConnectionState.CLOSED,
  },
  sessionId: "",
  runmode: "",
  activeFaults: [],
  takeoffRequested: false,
  commandeerRequested: false,
  obstacleSetting: { safety: 1.0, mode: VehicleSettings.ObstacleMarginMode.DEFAULT_OBSTACLES },
  // TODO(sam): add UI to customize keybindings
  // https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
  // Internet Explorer, Edge (16 and earlier), and Firefox (36 and earlier) use "Left", "Right",
  // "Up", and "Down" instead of "ArrowLeft", "ArrowRight", "ArrowUp", and "ArrowDown".
  movementKeybindings: {
    forwards: "w",
    backwards: "s",
    left: "a",
    right: "d",
    up: " ",
    down: "Shift",
    gimbalUp: "ArrowUp",
    gimbalDown: "ArrowDown",
    yawLeft: "ArrowLeft",
    yawRight: "ArrowRight",
  },
  teleopActionKeybindings: {
    toggleObstacleAvoidance: "r",
    togglePointerLock: "c",
    increaseSpeed: "]",
    decreaseSpeed: "[",
    zoomIn: "=",
    zoomOut: "-",
    increaseExposure: ".",
    decreaseExposure: ",",
    resetExposure: "/",
    takePhoto: "p",
    workflowNext: "n",
    workflowPrevious: "b",
    returnToDock: "Enter",
    cancel: "Backspace",
    toggleControlsMenu: "`",
    autoFocusCenter: "f",
    clearFocusCenter: "v",
    zoomOneShortcut: "1",
    zoomTwoShortcut: "2",
    zoomThreeShortcut: "3",
    zoomFourShortcut: "4",
    zoomFiveShortcut: "5",
    zoomSixShortcut: "6",
    toggleStrobeLights: "i",
    thermalToggle: "t",
    boost: "q",
    crawl: "e",
    resetGimbalPitch: "z",
    toggleRgbLights: "l",
    toggleARMarkers: "m",
    toggleARStreetOverlays: "o",
    __DEMO_ONLY_triggerDedroneAlert: "9",
  },
  keyboardMovementInputs: getZeroMovementInput(true),
  teleopActionInputs: {
    toggleObstacleAvoidance: -1,
    togglePointerLock: -1,
    increaseSpeed: -1,
    decreaseSpeed: -1,
    zoomIn: -1,
    zoomOut: -1,
    increaseExposure: -1,
    decreaseExposure: -1,
    resetExposure: -1,
    takePhoto: -1,
    workflowNext: -1,
    workflowPrevious: -1,
    returnToDock: -1,
    cancel: -1,
    toggleControlsMenu: -1,
    autoFocusCenter: -1,
    clearFocusCenter: -1,
    zoomOneShortcut: -1,
    zoomTwoShortcut: -1,
    zoomThreeShortcut: -1,
    zoomFourShortcut: -1,
    zoomFiveShortcut: -1,
    zoomSixShortcut: -1,
    toggleStrobeLights: -1,
    thermalToggle: -1,
    boost: -1,
    crawl: -1,
    resetGimbalPitch: -1,
    toggleRgbLights: -1,
    toggleARMarkers: -1,
    toggleARStreetOverlays: -1,
    __DEMO_ONLY_triggerDedroneAlert: -1,
  },
  teleopActionSources: {
    toggleObstacleAvoidance: "keyboard",
    togglePointerLock: "keyboard",
    increaseSpeed: "keyboard",
    decreaseSpeed: "keyboard",
    zoomIn: "keyboard",
    zoomOut: "keyboard",
    increaseExposure: "keyboard",
    decreaseExposure: "keyboard",
    resetExposure: "keyboard",
    takePhoto: "keyboard",
    workflowNext: "keyboard",
    workflowPrevious: "keyboard",
    returnToDock: "keyboard",
    cancel: "keyboard",
    toggleControlsMenu: "keyboard",
    autoFocusCenter: "keyboard",
    clearFocusCenter: "keyboard",
    zoomOneShortcut: "keyboard",
    zoomTwoShortcut: "keyboard",
    zoomThreeShortcut: "keyboard",
    zoomFourShortcut: "keyboard",
    zoomFiveShortcut: "keyboard",
    zoomSixShortcut: "keyboard",
    toggleStrobeLights: "keyboard",
    thermalToggle: "keyboard",
    boost: "keyboard",
    crawl: "keyboard",
    resetGimbalPitch: "keyboard",
    toggleRgbLights: "keyboard",
    toggleARMarkers: "keyboard",
    toggleARStreetOverlays: "keyboard",
    __DEMO_ONLY_triggerDedroneAlert: "keyboard",
  },
  mouseInput: {
    orientation: {},
    locked: false,
    lastMouseMove: -1,
  },
  gamepadConnected: false,
  gamepadMapping: "standard",
  gamepadSettings: initialGamepadSettings,
  desiredGamepadSettings: initialGamepadSettings,
  gamepadMovementInputs: getZeroMovementInput(false),
  clickToFly: {
    enabled: true, // user can disable click to fly
    clickStart: new Vector3(0, 0, 0),
    clickEnd: new Vector3(0, 0, 0),
    scrollEnd: new Vector3(0, 0, 0),
    active: -1, // records time click to fly is activated
    displayTrail: false, // if true displays a trail of markers between clickEnd and scroll End
    desired: true, // desiredState of click to fly
  },
  buttonInputs: {},
  flightId: "",
  flightPhaseId: FlightPhaseEnum.Enum.UNKNOWN,
  mostRecentPilot: {
    clientId: "",
    updatedAt: -1, // indicates we haven't received any pilot info yet
  },
  positionCommand: false,
  velocityCommand: false,
  desiredPose: {},
  desiredZoomLevel: 1,
  desiredVehicleNominalTranslationSpeed:
    getSpeedSettings()[VehicleSettings.ObstacleMarginMode.DEFAULT_OBSTACLES].default,
  vehicleNominalTranslationSpeed:
    getSpeedSettings()[VehicleSettings.ObstacleMarginMode.DEFAULT_OBSTACLES].default,
  zoomCorrectionFactor: 1.0,
  vehicleSpeedChangeTime: -1,
  vehicleSpeedSettings: getSpeedSettings()[VehicleSettings.ObstacleMarginMode.DEFAULT_OBSTACLES],
  crawlModeActive: false,
  boostModeActive: false,
  ignoreObstacleBasedSpeedSetting: false,
  displayPreciseTeleopAr: false,
  desiredDisplayPreciseTeleopAr: false,
  displayPointerLockInnerRing: true,
  goToWaypointRequest: { timestamp: -1 },
  goToWaypointStatus: {},
  thirdPersonViewActive: false,
  manualFlightBlockers: { blocked: false, blockers: [], blockerRemovalTimestamp: -1 },
  configReceived: false,
  defaultPoiHeight: DEFAULT_FLY_TO_POINT_HEIGHT,
  defaultOrbitInterceptMode: InterceptOrbitPoiMode_Enum.PERPENDICULAR,
  defaultOrbitDirection: "counterclockwise",
  defaultPathBetweenPointsMode: "upAndOver",
  defaultLookAtPointActive: false,
  lookAtFinalPointActive: true,
  nightSenseActive: false,
  deviceLinksSummary: {},
  deviceNodeHealth: {},
  droneSense: {
    requestStatus: "UNINITIALIZED",
  },
  lastKnownFlyingFlightId: "",
};

export interface KeyActionPayload {
  key: string;
  pressed: boolean;
  repeat: boolean; // tapped (False) or held (True)
}

export type ButtonKeys = "elevation" | "range";

export interface ButtonActionPayload {
  key: ButtonKeys;
  value?: number;
}

export interface FlightDeckAuthLevel {
  authLevel: ValueType<Auth.AccessLevelMap>;
  clientId: string;
}

/**
 * Helper function to return the next pilot client's ID for flight deck auth responses and pilot
 * response skybus messages.
 */
function nextPilotClientId(
  state: Readonly<PilotState>,
  { isPilot, clientId }: { isPilot: boolean; clientId: string }
): string {
  if (isPilot) {
    // clientId has become (or still is) the pilot
    return clientId;
  } else if (state.mostRecentPilot.clientId === clientId) {
    // current pilot has had access revoked
    return "";
  } else {
    // a non-pilot has authed with flight-deck, ignore
    return state.mostRecentPilot.clientId;
  }
}

function nextCommandeerRequested(state: Readonly<PilotState>) {
  return state.commandeerRequested ? !getIsMostRecentVehiclePilot(state) : false;
}

const {
  reducer,
  actions: pilotActions,
  caseReducers: pilotCaseReducers,
} = createSlice({
  name: "pilot",
  initialState,
  reducers: {
    handleLivekitConnectorState(
      state,
      { payload }: PayloadAction<{ clientId: string; connectionState: ConnectionState }>
    ) {
      state.vehicleConnection.clientId = payload.clientId;
      state.vehicleConnection.status = payload.connectionState;
      switch (payload.connectionState) {
        case ConnectionState.Reconnecting:
          state.vehicleConnection.active = true;
          state.vehicleConnection.success = false;
          break;
        case ConnectionState.Connected:
          state.vehicleConnection.active = false;
          state.vehicleConnection.success = true;
          break;
        case ConnectionState.Disconnected:
          state.vehicleConnection.active = false;
          state.vehicleConnection.success = false;
          break;
      }
    },
    handleFlightdeckAuthLevel(state, { payload }: PayloadAction<FlightDeckAuthLevel>) {
      const isPilot = (payload.authLevel ?? 0) >= Auth.AccessLevel.PILOT;
      state.mostRecentPilot.updatedAt = Date.now();
      state.mostRecentPilot.clientId = nextPilotClientId(state, {
        isPilot,
        clientId: payload.clientId,
      });
      state.commandeerRequested = nextCommandeerRequested(state);
    },
    handleVehicleStatus(state, action: PayloadAction<{ vehicleStatus: VehicleStatus }>) {
      state.flightState = action.payload.vehicleStatus.getFlightState();
      state.obstacleSetting.safety = action.payload.vehicleStatus.getObstacleSafety();
      // set obstacle mode if it has changed
      const obstacleMode = obstacleSafetyToObstacleMode(state.obstacleSetting.safety);
      if (state.obstacleSetting.mode !== obstacleMode) state.obstacleSetting.mode = obstacleMode;
      // set vehicle speed setting based on obstacle margin that the planner is using and whether
      // the user has fast flight enabled if obstacle based speed settings are enabled
      if (!state.ignoreObstacleBasedSpeedSetting) {
        const speedSettings = getSpeedSettings(
          /*nightSense*/ state.nightSenseActive,
          /*gpsNavigation*/ false,
          /*twilight*/ false
        )[obstacleMode];
        // only set speed settings if they have changed
        if (
          state.vehicleSpeedSettings.max !== speedSettings.max ||
          state.vehicleSpeedSettings.min !== speedSettings.min ||
          state.vehicleSpeedSettings.default !== speedSettings.default
        ) {
          state.vehicleSpeedSettings = speedSettings;
          state.vehicleNominalTranslationSpeed = speedSettings.default;
          state.desiredVehicleNominalTranslationSpeed = speedSettings.default;
        }
      }
      if (isInFlight(state) || isInPostFlight(state)) {
        state.takeoffRequested = false;
      }
      if (isInFlight(state)) {
        // record the last known flying flight id
        state.lastKnownFlyingFlightId = state.flightId;
      }
      state.rssi = action.payload.vehicleStatus.getRssi();
      state.vehicleAmbassadorStatusLastUpdated = new Date();
    },
    handleVehicleSettings(
      state,
      action: PayloadAction<{ vehicleSettings: VehicleSettings.AsObject }>
    ) {
      const twilightModeActive =
        action.payload.vehicleSettings.navigationMode === NavigationMode.Mode.TWILIGHT;
      const gpsFlightActive =
        action.payload.vehicleSettings.navigationMode === NavigationMode.Mode.AUTONOMY_OFF;
      const nightAutonomyNavigationMode =
        action.payload.vehicleSettings.navigationMode === NavigationMode.Mode.NIGHT_AUTONOMY;
      const nightSenseEnabled = !action.payload.vehicleSettings.nightsenseDisabled;
      state.nightSenseActive = nightAutonomyNavigationMode && nightSenseEnabled;
      // make twilight mode use the default obstacle aggressive speed settings if twilight mode
      // fast flight is enabled or if we're in GPS flight
      let desiredSpeedSettings = undefined;
      if (twilightModeActive) {
        // this ensures that any obstacles safety value based speed setting does not override this
        state.ignoreObstacleBasedSpeedSetting = true;
        desiredSpeedSettings = getSpeedSettings(
          /*nightSense*/ false,
          /*gps*/ false,
          /*twilightMode*/ true
        )[VehicleSettings.ObstacleMarginMode.NO_OBSTACLES];
      } else if ((nightAutonomyNavigationMode && !nightSenseEnabled) || gpsFlightActive) {
        state.ignoreObstacleBasedSpeedSetting = true;
        desiredSpeedSettings = getSpeedSettings(
          /*nightSense*/ false,
          /*gpsNavigation*/ true,
          /*twilight*/ false
        )[VehicleSettings.ObstacleMarginMode.NO_OBSTACLES];
      } else {
        state.ignoreObstacleBasedSpeedSetting = false;
      }
      if (desiredSpeedSettings !== undefined) {
        // change speed settings only the first time we get this message, resolves SW-54332
        if (JSON.stringify(state.vehicleSpeedSettings) !== JSON.stringify(desiredSpeedSettings)) {
          state.vehicleSpeedSettings = desiredSpeedSettings;
          state.vehicleNominalTranslationSpeed = desiredSpeedSettings.default;
          state.desiredVehicleNominalTranslationSpeed = desiredSpeedSettings.default;
        }
      }
    },
    handleActiveFaults(state, action: PayloadAction<number[]>) {
      state.activeFaults = action.payload;
    },
    handleFlight(state, action: PayloadAction<Flight>) {
      state.flightId = action.payload.getFlightId();
    },
    requestTakeoff(state) {
      state.takeoffRequested = true;
    },
    cancelTakeoffRequest(state) {
      state.takeoffRequested = false;
    },
    requestCommandeer(state) {
      state.takeoffRequested = true;
      state.commandeerRequested = true;
    },
    handleKeyboardInput(state, { payload }: PayloadAction<KeyActionPayload>) {
      // register movement input if the key is a movement key, and manual flight hasn't been blocked
      // for atleast 250 ms
      const direction = getDirectionForKey(state, payload.key);
      const blockerTimeout = performance.now() - state.manualFlightBlockers.blockerRemovalTimestamp;
      const vehicleIsLanding = isLanding(state);
      if (direction && !state.manualFlightBlockers.blocked && blockerTimeout > 250) {
        if (payload.pressed) {
          // record the time that the key is first pressed
          if (state.keyboardMovementInputs[direction] < 0) {
            state.keyboardMovementInputs[direction] = performance.now();
          }
        } else {
          state.keyboardMovementInputs[direction] = -1;
        }
        // check whether any keyboard keys are being held (versus tapped)
        const noKeyHeld = Object.values(state.keyboardMovementInputs).every(
          value => value < 0 || performance.now() - value < secondsToMilliseconds(TAP_DURATION)
        );
        // set a position command if no key is held down and velocity otherwise, unless we are
        // landing in which case we always set a velocity command, since nudging during landing
        // only works with velocity commands.
        state.positionCommand = noKeyHeld && !vehicleIsLanding;
        state.velocityCommand = !noKeyHeld || vehicleIsLanding;
      }
      const action = getActionForKey(state, payload.key);
      if (action) {
        // record timestamp of first press (to enable detection of press and hold)
        if (payload.pressed && state.teleopActionInputs[action] < 0) {
          state.teleopActionInputs[action] = performance.now();
        }
        if (!payload.pressed) {
          state.teleopActionInputs[action] = -1;
        }
      }
    },
    setGamepadConnected(
      state,
      { payload }: PayloadAction<{ connected: boolean; mapping: GamepadMappingType }>
    ) {
      const antMessageClassName = getTeleopAntMessageClassName();
      // TODO(divyanshu): move the messages to a separate component so we can use LargeMessageConfig
      // we only support standard mapping for now
      state.gamepadMapping = payload.mapping;
      if (payload.connected) {
        if (!isGamepadSupported(payload.mapping)) {
          toast.warning("Gamepad not compatible. Please use a gamepad with standard mapping.", {
            className: antMessageClassName,
            id: "gamepad-mapping-error",
            duration: Infinity,
          });
        }
      } else {
        // remove the message when gamepad is disconnected
        toast.dismiss("gamepad-mapping-error");
      }
      // get connected state and display connection message if mapping is okay
      if (state.gamepadConnected !== payload.connected) {
        if (isGamepadSupported(payload.mapping)) {
          toast.info(payload.connected ? "Gamepad connected" : "Gamepad disconnected", {
            className: antMessageClassName,
            id: "gamepad-connection",
          });
        }
        state.gamepadConnected = payload.connected;
      }
    },
    changeGamepadMode(state, { payload }: PayloadAction<GamepadMode>) {
      state.gamepadSettings.mode = payload;
    },
    changeGamepadExpoLevel(state, { payload }: PayloadAction<number>) {
      state.gamepadSettings.expoLevel.value = payload;
    },
    changeGamepadThrottleSensitivity(state, { payload }: PayloadAction<number>) {
      state.gamepadSettings.throttleSensitivity.value = payload;
    },
    changeGamepadYawSensitivity(state, { payload }: PayloadAction<number>) {
      state.gamepadSettings.yawSensitivity.value = payload;
    },
    changeGamepadPitchSensitivity(state, { payload }: PayloadAction<number>) {
      state.gamepadSettings.pitchSensitivity.value = payload;
    },
    changeDesiredGamepadMode(state, { payload }: PayloadAction<GamepadMode>) {
      state.desiredGamepadSettings.mode = payload;
    },
    changeDesiredGamepadExpoLevel(state, { payload }: PayloadAction<number>) {
      state.desiredGamepadSettings.expoLevel.value = payload;
    },
    changeDesiredGamepadThrottleSensitivity(state, { payload }: PayloadAction<number>) {
      state.desiredGamepadSettings.throttleSensitivity.value = payload;
    },
    changeDesiredGamepadYawSensitivity(state, { payload }: PayloadAction<number>) {
      state.desiredGamepadSettings.yawSensitivity.value = payload;
    },
    changeDesiredGamepadPitchSensitivity(state, { payload }: PayloadAction<number>) {
      state.desiredGamepadSettings.pitchSensitivity.value = payload;
    },
    handleGamepadMovementInput(
      state,
      { payload }: PayloadAction<{ [key in GamepadMovementInputs]?: number }>
    ) {
      const mapping = gamepadModeMappings[state.gamepadSettings.mode];
      for (const inputInfo of supportedGamepadMovementInputs()) {
        const value = payload[inputInfo.input];
        if (value !== undefined) {
          const directions = mapping[inputInfo.input];
          // apply cubic deadzone function to get stick value for use in control
          const GAMEPAD_EXPO_LEVEL = state.gamepadSettings.expoLevel.value;
          const transformedStickValue = cubicDeadzoneFunction(
            GAMEPAD_DEADZONE,
            GAMEPAD_EXPO_LEVEL
          )(value);
          // set all directions corresponding to axis to 0 if the transformed value is 0, which means
          // that we are within deadzone
          if (Math.abs(transformedStickValue) < kMilliTol) {
            for (const direction of directions) {
              state.gamepadMovementInputs[direction] = 0;
            }
          } else {
            // a gamepad axis can have multiple directions if it is a joystick and not a button
            // directions[0] is for the -'ve direction, directions[1] is for the +'ve direction
            const intendedDirection =
              directions.length > 1 && transformedStickValue > 0 ? directions[1] : directions[0];
            state.gamepadMovementInputs[intendedDirection] = Math.abs(transformedStickValue);
          }
        }
      }
      // clear desiredPose and clickToFly if the gamepad is being used
      pilotCaseReducers.clearDesiredPose(state);
      pilotCaseReducers.clearClickToFly(state);
    },
    setTeleopActionSources(state, { payload }: PayloadAction<{ [key in TeleopAction]?: boolean }>) {
      for (const [action, gamepad] of Object.entries(payload)) {
        state.teleopActionSources[action as TeleopAction] = gamepad ? "gamepad" : "keyboard";
      }
    },
    disablePositionCommand(state) {
      state.positionCommand = false;
      state.velocityCommand = true;
    },
    handleButtonInput(state, { payload }: PayloadAction<ButtonActionPayload>) {
      state.buttonInputs[payload.key] = payload.value;
    },
    handlePointerLockState(state, { payload: nowLocked }: PayloadAction<boolean>) {
      state.mouseInput.locked = nowLocked;
      if (!nowLocked) {
        // Exiting pointer lock causes weird behavior with the keyboard event handlers; easiest to
        // just reset our input state so we don't get "sticky" keys
        state.keyboardMovementInputs = initialState.keyboardMovementInputs;
        // clear pointer lock orientation when we exit
        state.mouseInput.orientation = {};
      } else {
        // clear any desired pose when entering pointer lock, otherwise we will snap back to this
        // heading when exiting pointer lock
        pilotCaseReducers.clearDesiredPose(state);
        state.mouseInput.orientation = {};
      }
    },
    handleMouseInput(state, { payload }: PayloadAction<Partial<MouseOrientation>>) {
      // set the mouse input only if it has changed to make sure the timstamp set is consistent with
      // the timestamp of the command
      const pitchChanged = !areNumbersApproximatelyEqual(
        state.mouseInput.orientation.pitch,
        payload.pitch
      );
      const headingChanged = !areNumbersApproximatelyEqual(
        state.mouseInput.orientation.heading,
        payload.heading
      );
      if (headingChanged || pitchChanged) {
        state.mouseInput.orientation = payload;
        state.mouseInput.lastMouseMove = performance.now();
      }
    },
    setClickToFly(
      state,
      action: PayloadAction<{ clickStart: Vector3; clickEnd: Vector3; scrollEnd: Vector3 }>
    ) {
      state.clickToFly.active = performance.now();
      state.clickToFly.clickStart = action.payload.clickStart.clone();
      state.clickToFly.clickEnd = action.payload.clickEnd.clone();
      state.clickToFly.scrollEnd = action.payload.scrollEnd.clone();

      // add a manual flight blocker so that keyboard inputs don't command a desired position
      if (!state.manualFlightBlockers.blockers.includes(MANUAL_FLIGHT_BLOCKERS.CLICK_TO_FLY)) {
        state.manualFlightBlockers.blockers.push(MANUAL_FLIGHT_BLOCKERS.CLICK_TO_FLY);
        state.manualFlightBlockers.blocked = true;
      }
    },
    clearClickToFly(state) {
      state.clickToFly.active = -1;
      // set the mouse input to the current camera orientation to prevent it snapping back to what
      // it was before click to fly once regular teleop resumes
      const navTGimbalCamImu = state.nav_T_gimbal_camera_imu
        ? convertTransPbToObject3(state.nav_T_gimbal_camera_imu.transform)
        : undefined;
      const imuTCam = state.imu_T_subject_cam
        ? convertTransPbToObject3(state.imu_T_subject_cam)
        : undefined;
      const gimbalCamRImu = state.gimbal_cam_R_imu;
      const navTCommand = calculateNavTCommand(navTGimbalCamImu, imuTCam, gimbalCamRImu);
      const orientation = calculateNavHeadingPitch(navTCommand);
      if (orientation !== undefined) {
        state.mouseInput.orientation = orientation;
      }
      // remove the click to fly manual flight blocker
      state.manualFlightBlockers.blockers = state.manualFlightBlockers.blockers.filter(
        blocker => blocker !== MANUAL_FLIGHT_BLOCKERS.CLICK_TO_FLY
      );
      state.manualFlightBlockers.blocked = state.manualFlightBlockers.blockers.length > 0;
      state.manualFlightBlockers.blockerRemovalTimestamp = performance.now();
    },
    toggleCrawlModeActive(state) {
      state.crawlModeActive = !state.crawlModeActive;
      // If crawl is enabled, make sure to disable boost. Resolves SW-67722.
      state.boostModeActive = false;
    },
    clearCrawlMode(state) {
      state.crawlModeActive = false;
    },
    toggleBoostModeActive(state) {
      state.boostModeActive = !state.boostModeActive;
      // If boost is enabled, make sure to disable crawl. Resolves SW-67722.
      state.crawlModeActive = false;
    },
    clearBoostMode(state) {
      state.boostModeActive = false;
    },
    handleSkillStatus(state, { payload }: PayloadAction<SkillStatusMinimal.AsObject>) {
      state.skillState = payload.skillKey;
    },
    handleBlockingPrompt(state, action: PayloadAction<BlockingPrompt.AsObject | undefined>) {
      state.blockingPrompt = action.payload;
    },
    setSessionId(state, action: PayloadAction<string>) {
      state.sessionId = action.payload;
    },
    setRunmode(state, action: PayloadAction<string>) {
      state.runmode = action.payload;
    },
    setCameraTrans(state, { payload }: PayloadAction<TransPb.AsObject>) {
      state.imu_T_subject_cam = payload;
    },
    setGimbalTrans(state, { payload }: PayloadAction<TransPb.AsObject>) {
      state.nav_T_gimbal_camera_imu = { transform: payload, timestamp: performance.now() };
    },
    setCameraInstrinsics(state, { payload }: PayloadAction<CameraIntrinsics.AsObject>) {
      state.cameraIntrinsics = payload;
    },
    handleMotionPlannerStatus(state, action: PayloadAction<MotionPlannerStatus.AsObject>) {
      if (!state.movingAutonomously && action.payload.isMovingAutonomously) {
        // If the vehicle enters a state of autonomous flight, unlock the pointer
        // and clear the desired pose so the vehicle does not snap back to these points after
        state.mouseInput.locked = false;
        pilotCaseReducers.clearDesiredPose(state);
        pilotCaseReducers.clearClickToFly(state);
        pilotCaseReducers.clearCrawlMode(state);
        pilotCaseReducers.clearBoostMode(state);
        state.mouseInput.orientation = {};
      }
      state.movingAutonomously = action.payload.isMovingAutonomously;
      state.policyCommandType = action.payload.policyCommandType;
    },
    handlePilotResponse(state, { payload }: PayloadAction<PilotResponse.AsObject>) {
      state.mostRecentPilot.updatedAt = Date.now();
      state.mostRecentPilot.clientId = nextPilotClientId(state, payload);
      state.commandeerRequested = nextCommandeerRequested(state);
    },
    handleFlightDeckServerInfo(state, { payload }: PayloadAction<ServerInfo.AsObject>) {
      state.mostRecentPilot.updatedAt = Date.now();
      state.mostRecentPilot.clientId =
        // only store the pilot if its more recent than the stale threshold
        payload.secondsSincePilotContact < PILOT_STALE_THRESHOLD ? payload.pilotId : "";
      state.commandeerRequested = nextCommandeerRequested(state);
    },
    clearDirectionalKeyboardInputs(state) {
      for (const input of Object.keys(state.keyboardMovementInputs)) {
        state.keyboardMovementInputs[input as MovementDirection] = -1;
      }
      pilotCaseReducers.clearDesiredPose(state);
    },
    clearMouseInput(state) {
      state.mouseInput.orientation = {};
      state.mouseInput.lastMouseMove = -1;
      state.mouseInput.locked = false;
    },
    clearDirectionalGamepadInputs(state) {
      for (const input of Object.keys(state.gamepadMovementInputs)) {
        state.gamepadMovementInputs[input as MovementDirection] = 0;
      }
    },
    clearKeyboardTeleopActionInputs(state) {
      for (const action of Object.keys(state.teleopActionInputs)) {
        if (state.teleopActionSources[action as TeleopAction] === "keyboard") {
          state.teleopActionInputs[action as TeleopAction] = -1;
        }
      }
    },
    clearGamepadTeleopActionInputs(state) {
      for (const action of Object.keys(state.teleopActionInputs)) {
        if (state.teleopActionSources[action as TeleopAction] === "gamepad") {
          state.teleopActionInputs[action as TeleopAction] = -1;
        }
      }
    },
    addManualFlightBlocker(state, { payload }: PayloadAction<MANUAL_FLIGHT_BLOCKERS>) {
      // add the blocker if it's not already there
      if (!state.manualFlightBlockers.blockers.includes(payload)) {
        state.manualFlightBlockers.blockers.push(payload);
        state.manualFlightBlockers.blocked = true;
      }
    },
    removeManualFlightBlocker(state, { payload }: PayloadAction<MANUAL_FLIGHT_BLOCKERS>) {
      // remove specific blocker
      state.manualFlightBlockers.blockers = state.manualFlightBlockers.blockers.filter(
        blocker => blocker !== payload
      );
      state.manualFlightBlockers.blocked = state.manualFlightBlockers.blockers.length > 0;
      state.manualFlightBlockers.blockerRemovalTimestamp = performance.now();
    },
    clearManualFlightBlockers(state) {
      state.manualFlightBlockers.blocked = false;
      state.manualFlightBlockers.blockers = [];
      state.manualFlightBlockers.blockerRemovalTimestamp = performance.now();
    },
    clearTeleopActionSources(state) {
      for (const action of Object.keys(state.teleopActionSources)) {
        state.teleopActionSources[action as TeleopAction] = "keyboard";
      }
    },
    setVehiclePosition(state, { payload }: PayloadAction<Array<number>>) {
      state.vehiclePosition = new Vector3(payload[0], payload[1], payload[2]);
    },
    setVehicleVelocity(state, { payload }: PayloadAction<Array<number>>) {
      const translationalVelocity = new Vector3(payload[0], payload[1], payload[2]);
      state.vehicleVelocity = translationalVelocity;
    },
    setDesiredPose(
      state,
      { payload }: PayloadAction<{ position?: Vector3; heading?: number; pitch?: number }>
    ) {
      // Check if the desired pose has changed and only set it if it has to make sure the timstamp
      // set is consistent with the timestamp of the command
      const positionChanged = !areVectorsApproximatelyEqual(
        payload.position,
        state.desiredPose.position
      );
      const headingChanged = !areNumbersApproximatelyEqual(
        payload.heading,
        state.desiredPose.heading
      );
      const pitchChanged = !areNumbersApproximatelyEqual(payload.pitch, state.desiredPose.pitch);
      if (positionChanged || headingChanged || pitchChanged) {
        state.desiredPose = {
          position: payload.position,
          heading: payload.heading,
          pitch: payload.pitch,
          timestamp: performance.now(),
        };
      }
    },
    resetGimbalPitch(state) {
      state.desiredPose.pitch = 0;
      state.desiredPose.timestamp = performance.now();
    },
    clearDesiredPose(state) {
      state.desiredPose = {};
      state.positionCommand = false;
    },
    setVehicleTranslationSpeed(state, { payload }: PayloadAction<number>) {
      state.vehicleNominalTranslationSpeed = payload;
      state.vehicleSpeedChangeTime = performance.now();
    },
    changeDesiredVehicleTranslationSpeed(state, { payload }: PayloadAction<number>) {
      state.desiredVehicleNominalTranslationSpeed = payload;
      state.vehicleSpeedChangeTime = performance.now();
    },
    setDesiredZoomLevel(state, { payload }: PayloadAction<number>) {
      state.desiredZoomLevel = payload;
      state.zoomCorrectionFactor = getZoomCorrection(payload);
    },
    incrementVehicleTranslationSpeed(state, { payload }: PayloadAction<number>) {
      const desiredNominalTranslationSpeed = state.vehicleNominalTranslationSpeed + payload;
      const antMessageClassName = getTeleopAntMessageClassName();
      // display messages depending on speed increment commanded
      if (payload < 0) {
        if (desiredNominalTranslationSpeed < state.vehicleSpeedSettings.min) {
          toast.warning("Already at minimum speed", {
            className: antMessageClassName,
            id: "speed-hotkey",
            duration: 3000,
          });
        } else {
          toast.info("Speed setting decreased", {
            className: antMessageClassName,
            id: "speed-hotkey",
            duration: 3000,
          });
        }
      } else {
        if (desiredNominalTranslationSpeed > state.vehicleSpeedSettings.max) {
          toast.warning("Already at maximum speed", {
            className: antMessageClassName,
            id: "speed-hotkey",
            duration: 3000,
          });
        } else {
          toast.info("Speed setting increased", {
            className: antMessageClassName,
            id: "speed-hotkey",
            duration: 3000,
          });
        }
      }
      state.desiredVehicleNominalTranslationSpeed = _.clamp(
        desiredNominalTranslationSpeed,
        state.vehicleSpeedSettings.min,
        state.vehicleSpeedSettings.max
      );
      state.vehicleNominalTranslationSpeed = _.clamp(
        desiredNominalTranslationSpeed,
        state.vehicleSpeedSettings.min,
        state.vehicleSpeedSettings.max
      );
      state.vehicleSpeedChangeTime = performance.now();
    },
    displayPreciseTeleopAr(state, { payload }: PayloadAction<boolean>) {
      state.displayPreciseTeleopAr = payload;
    },
    changeDesiredDisplayPreciseTeleopAr(state, { payload }: PayloadAction<boolean>) {
      state.desiredDisplayPreciseTeleopAr = payload;
    },
    displayPointerLockInnerRing(state, { payload }: PayloadAction<boolean>) {
      state.displayPointerLockInnerRing = payload;
    },
    displayClickToFlyTrail(state, { payload }: PayloadAction<boolean>) {
      state.clickToFly.displayTrail = payload;
    },
    enableClickToFly(state, { payload }: PayloadAction<boolean>) {
      state.clickToFly.enabled = payload;
    },
    changeDesiredClickToFly(state, { payload }: PayloadAction<boolean>) {
      state.clickToFly.desired = payload;
    },
    setGoToWaypointRequest(
      state,
      {
        payload,
      }: PayloadAction<{
        target: Waypoint.AsObject;
        useGlobalGraph: boolean; // set to true if the target is in a previously explored area
        // only define these if you want non-default behavior (default defined in pilot/utils.ts)
        lookAtArgs?: LookAtMotionArgs.AsObject;
        traversalArgs?: TraversalMotionArgs.AsObject;
        graphArgs?: GraphMotionArgs.AsObject;
      }>
    ) {
      state.goToWaypointRequest = {
        ...payload,
        timestamp: performance.now(),
      };
      if (!state.manualFlightBlockers.blockers.includes(MANUAL_FLIGHT_BLOCKERS.GO_TO_WAYPOINT)) {
        state.manualFlightBlockers.blockers.push(MANUAL_FLIGHT_BLOCKERS.GO_TO_WAYPOINT);
        state.manualFlightBlockers.blocked = true;
      }
    },
    clearGoToWaypointRequest(state) {
      // clear waypoint request if we have one
      if (state.goToWaypointRequest.target !== undefined) {
        state.goToWaypointRequest = { timestamp: performance.now() };
        // remove the go to waypoint manual flight blocker
        state.manualFlightBlockers.blockers = state.manualFlightBlockers.blockers.filter(
          blocker => blocker !== MANUAL_FLIGHT_BLOCKERS.GO_TO_WAYPOINT
        );
        state.manualFlightBlockers.blocked = state.manualFlightBlockers.blockers.length > 0;
        state.manualFlightBlockers.blockerRemovalTimestamp = performance.now();
      }
    },
    handleGoToWaypointStatus(state, { payload }: PayloadAction<GoToWaypointStatus>) {
      const antMessageClassName = getTeleopAntMessageClassName();
      // set waypoint state
      const waypointState = payload.getState();
      const waypointSource = payload.getSource();
      const waypointTarget = payload.getTarget();
      state.goToWaypointStatus = { state: waypointState, source: waypointSource };
      // if we are in these states, we are actively flying to the waypoint
      const waypointActive =
        waypointState === GoToWaypointState.Enum.PENDING ||
        waypointState === GoToWaypointState.Enum.IN_PROGRESS ||
        waypointState === GoToWaypointState.Enum.FINAL_ADJUSTMENT;
      const waypointDone =
        waypointState === GoToWaypointState.Enum.DONE ||
        waypointState === GoToWaypointState.Enum.FAILED;
      // all waypoints from teleop will be set with the source as CLIENT
      const teleopWaypoint = waypointSource === GoToWaypointSource.Enum.CLIENT;
      // clear the state when we reach (or give up going to) the user-defined waypoint
      if (waypointDone && teleopWaypoint) {
        pilotCaseReducers.clearGoToWaypointRequest(state);
      }
      // is the waypoint an RTD waypoint?
      const returningToDock =
        state.returnToDockInfo?.returnPhase === ReturnToDockInfo.ReturnPhase.RETURNING_TO_DOCK;
      const targetKey = waypointTarget?.getKey()?.getValue();
      const targetIsReturn =
        targetKey === ReservedWaypointKeys.Enum.DOCK ||
        targetKey === ReservedWaypointKeys.Enum.HOME_POINT ||
        targetKey === ReservedWaypointKeys.Enum.LAUNCH ||
        targetKey === ReservedWaypointKeys.Enum.LAUNCH_NAV;

      // display message if we are flying to a teleop waypoint and it is not an RTD
      if (
        teleopWaypoint &&
        waypointActive &&
        !waypointDone &&
        !returningToDock &&
        !targetIsReturn
      ) {
        toast.info("Flying to waypoint. Press any key to stop.", {
          className: antMessageClassName,
          duration: Infinity, // don't auto-dismiss the message until we don't have a waypoint any more
          id: "waypoint-command",
        });
      } else {
        toast.dismiss("waypoint-command");
      }

      // NOTE(divyanshu): we can get a lot more information from this message (e.g. progress) which
      // could be interesting to display to the user.
    },
    handleReturnToDockInfo(state, { payload }: PayloadAction<ReturnToDockInfo.AsObject>) {
      state.returnToDockInfo = payload;
    },
    handleSafeLandingInfo(state, { payload }: PayloadAction<SafeLandingInfo.AsObject>) {
      state.safeLandingInfo = payload;

      if (payload.targetId) {
        state.latestValidTargetLandingPointId = payload.targetId;
      }
    },
    handleGimbalFlashlightStatus(
      state,
      { payload }: PayloadAction<GimbalFlashlightStatus.AsObject>
    ) {
      state.gimbalFlashlightStatus = payload;
    },
    setThirdPersonView(state, { payload }: PayloadAction<boolean>) {
      state.thirdPersonViewActive = payload;
    },
    handleStreamCameraCalibration: (
      state,
      { payload }: PayloadAction<StreamCameraCalibration.AsObject>
    ) => {
      state.streamCameraCalibration = payload;

      const eoImuTCam = payload.eoCamera?.imuTCam;
      if (eoImuTCam) {
        state.eo_imu_T_cam = toTransPb(eoImuTCam, payload.utime);
      }

      const irImuTCam = payload.irCamera?.imuTCam;
      if (irImuTCam) {
        state.ir_imu_T_cam = toTransPb(irImuTCam, payload.utime);
      }

      const gimbalCamRImu = payload.gimbalCamRImu;
      if (gimbalCamRImu) {
        state.gimbal_cam_R_imu = new Quaternion().fromArray(gimbalCamRImu.xyzwList);
      }
    },
    setConfigReceived(state, { payload }: PayloadAction<boolean>) {
      state.configReceived = payload;
    },
    handleLandingPadTrackerState(
      state,
      { payload }: PayloadAction<LandingPadTrackerSimplifiedState.AsObject>
    ) {
      state.landingPadTracker = payload;
    },
    clearLandingPadTrackerState(state) {
      state.landingPadTracker = undefined;
    },
    setDefaultPoiHeight(state, { payload }: PayloadAction<number>) {
      state.defaultPoiHeight = payload;
    },
    handleUserCameraAutoRegionState(
      state,
      { payload }: PayloadAction<MinimalAutoRegionState.AsObject>
    ) {
      state.userCameraAutoRegionState = payload;
    },
    setDefaultOrbitInterceptMode(state, { payload }: PayloadAction<InterceptOrbitPoiMode_Enum>) {
      state.defaultOrbitInterceptMode = payload;
    },
    setDefaultOrbitDirection(
      state,
      { payload }: PayloadAction<PilotState["defaultOrbitDirection"]>
    ) {
      state.defaultOrbitDirection = payload;
    },
    setDefaultPathBetweenPointsMode(
      state,
      { payload }: PayloadAction<PilotState["defaultPathBetweenPointsMode"]>
    ) {
      state.defaultPathBetweenPointsMode = payload;
    },
    setDefaultLookAtPointActive(state, { payload }: PayloadAction<boolean>) {
      state.defaultLookAtPointActive = payload;
    },
    handleFlightPhasePb(state, { payload }: PayloadAction<FlightPhasePb.AsObject>) {
      state.flightId = payload.flightId;
      state.flightPhaseId = payload.phaseId;
    },
    handleFlightPhaseLcm(state, { payload }: PayloadAction<flight_phase_t>) {
      state.flightId = payload.flight_id;
      state.flightPhaseId = payload.phase_id.value as unknown as ValueType<FlightPhaseEnum.EnumMap>;
    },
    setLookAtFinalPointActive(state, { payload }: PayloadAction<boolean>) {
      state.lookAtFinalPointActive = payload;
    },
    handleAttachmentPortStatus(state, { payload }: PayloadAction<PortStatusToConsumers.AsObject>) {
      state.attachmentPortStatus = payload;
    },
    handleSpotlightSystemStatus(state, { payload }: PayloadAction<SpotlightSystemStatus>) {
      state.spotlightSystemStatus = payload;
    },
    handleDeviceLinksSummary(state, { payload }: PayloadAction<DeviceLinksSummary>) {
      state.deviceLinksSummary = {
        ...state.deviceLinksSummary,
        [payload.getSerial()]: payload,
      };
    },
    handleDeviceNodeHealthSummary(state, { payload }: PayloadAction<DeviceNodeHealth>) {
      state.deviceNodeHealth = {
        ...state.deviceNodeHealth,
        [payload.getSerial()]: payload,
      };
    },
    resetSkillState(state) {
      state.skillState = undefined;
    },
    handleSpeakerMicStatus(state, { payload }: PayloadAction<SpeakerMicStatus>) {
      state.speakerMicStatus = payload;
    },
    handleSpeakerMicAudioFiles(state, { payload }: PayloadAction<SpeakerMicAudioFiles>) {
      state.speakerMicAudioFiles = payload;
    },
    // DroneSense mission integration
    handleDroneSenseRequestStatusUpdated(
      state,
      { payload }: PayloadAction<"UNINITIALIZED" | "REQUESTED" | "ERROR">
    ) {
      state.droneSense.requestStatus = payload;
    },
    handleDroneSenseMissionListUpdated(
      state,
      {
        payload: { missions, selectedMissionId, callSign },
      }: PayloadAction<{
        missions: DroneSenseMission[];
        selectedMissionId?: string;
        callSign: string;
      }>
    ) {
      state.droneSense = {
        requestStatus: "SUCCESS",
        callSign,
        selectedMissionId: selectedMissionId ?? missions[0]?.missionId,
        missions,
      };
    },
    handleDroneSenseMissionSelected(state, { payload }: PayloadAction<string>) {
      if (state.droneSense.requestStatus === "SUCCESS") {
        state.droneSense.selectedMissionId = payload;
      }
    },
    handleDroneSenseCallSignUpdated(state, { payload }: PayloadAction<string>) {
      if (state.droneSense.requestStatus === "SUCCESS") {
        state.droneSense.callSign = payload;
      }
    },
  },
  extraReducers: builder =>
    builder
      .addCase(teleopActions.updateTeleopSessionId, state => {
        // Clear state when we get a new teleop sesion ID; we do not clear takeoffRequested to ensure
        // the result of autoPrep doesn't get clobbered on the initial app load
        return { ...initialState, takeoffRequested: state.takeoffRequested };
      })
      .addCase(teleopActions.requestPilot, (state, action) => {
        // request commandeer/takeoff if pilot is requested with commandeer option
        if (action.payload.options?.commandeer === true) {
          state.commandeerRequested = true;
          state.takeoffRequested = true;
        }
      }),
});

export { pilotActions };
export default reducer;
