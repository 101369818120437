import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";

import { FlightState } from "@skydio/pbtypes/pbtypes/gen/ambassador/flight_state_pb";

import type { ValueType } from "@skydio/core";
import type { MovementDirection, PilotState, TeleopAction } from "./types";

export const getClientId = (state: PilotState) => state.vehicleConnection.clientId;

export const getMostRecentPilotId = (state: PilotState) => state.mostRecentPilot.clientId;

export const wasCommandeerRequested = (state: PilotState) => state.commandeerRequested;

export const getDirectionForKey = (state: PilotState, targetKey: string) =>
  _.findKey(state.movementKeybindings, key => key === targetKey) as MovementDirection | undefined;

export const getActionForKey = (state: PilotState, targetKey: string) =>
  _.findKey(state.teleopActionKeybindings, key => key === targetKey) as TeleopAction | undefined;

export const getCurrentFlightState = (state: PilotState) => state.flightState;

const createFlightStateMatchSelector = (...flightStates: ValueType<FlightState.EnumMap>[]) =>
  createSelector(getCurrentFlightState, flightState =>
    _.some(flightStates, f => f === flightState)
  );

export const isResting = createFlightStateMatchSelector(FlightState.Enum.REST);
export const isReadyForTakeoff = createFlightStateMatchSelector(
  FlightState.Enum.HAND_TAKEOFF_READY
);
export const isTakingOff = createFlightStateMatchSelector(FlightState.Enum.TAKING_OFF);
export const isLanding = createFlightStateMatchSelector(
  FlightState.Enum.LANDING,
  FlightState.Enum.LANDING_WITH_AVOIDANCE,
  FlightState.Enum.DOCK_LANDING,
  FlightState.Enum.SMART_LAND_PREP,
  FlightState.Enum.PAUSED_FOR_FINAL_DESCENT,
  FlightState.Enum.CASE_LANDING,
  FlightState.Enum.EMERGENCY_LANDING
);
export const isAbortingLanding = createFlightStateMatchSelector(FlightState.Enum.ABORTING_LANDING);
export const isReturningToDock = createFlightStateMatchSelector(FlightState.Enum.RETURNING_TO_DOCK);
export const isReturningToLaunch = createFlightStateMatchSelector(
  FlightState.Enum.RETURNING_TO_LAUNCH
);
export const isReturningToSafeLanding = createFlightStateMatchSelector(
  FlightState.Enum.RETURNING_TO_SAFE_LANDING
);
export const isInFlight = createSelector(
  getCurrentFlightState,
  // NOTE(sam): maybe worth implementing more nuanced logic here but for now we can rely on
  // the enum values for all potential in-flight statuses being between 200 and 300
  (flightState = FlightState.Enum.INVALID) =>
    _.inRange(flightState, FlightState.Enum.FLYING_UNKNOWN, FlightState.Enum.TAKING_OFF)
);
export const getIsMostRecentVehiclePilot = createSelector(
  [getClientId, getMostRecentPilotId],
  (clientId, pilotId) => {
    return clientId === pilotId && clientId !== "";
  }
);
export const isInPostFlight = createFlightStateMatchSelector(FlightState.Enum.POST_FLIGHT);
export const isInFlyingUnknownState = createFlightStateMatchSelector(
  FlightState.Enum.FLYING_UNKNOWN
);
export const isLandedInDock = createFlightStateMatchSelector(
  FlightState.Enum.ARMED_IN_DOCK_POST_FLIGHT
);
/**
 * Returns true if the vehicle is in a state where it is waiting for takeoff. Includes rest phase
 */
export const isWaitingForTakeoff = createSelector(getCurrentFlightState, flightState => {
  return (
    flightState &&
    flightState >= FlightState.Enum.REST &&
    flightState < FlightState.Enum.POST_FLIGHT
  );
});
