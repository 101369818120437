import enums_pb2 from "@skydio/pbtypes/pbtypes/tools/cloud_api/enums_pb";
import stats_pb2 from "@skydio/pbtypes/pbtypes/tools/cloud_api/stats_pb";
import type { ValueType } from "@skydio/core";

export const StatisticUnitType = enums_pb2.UnitType.UnitTypeEnum;
export type StatisticUnitType = ValueType<enums_pb2.UnitType.UnitTypeEnumMap>;
export const StatisticUnit = enums_pb2.Unit.UnitEnum;
export type StatisticUnit = ValueType<enums_pb2.Unit.UnitEnumMap>;

export type Statistic = stats_pb2.Statistic.AsObject;
