import { combineReducers } from "@reduxjs/toolkit";
import { connectRouter } from "connected-react-router";

import { viewerReducer as viewer } from "@skydio/3d_viewer_lib/src/state/store";
import * as CLOUD_API from "@skydio/api_util/src/backends/cloud_api";

import ar from "./ar/slice";
import currentUser from "./current_user/slice";
import dashboard from "./dashboard/slice";
import downloadFiles from "./download_files/slice";
import fileSelector from "./file_selector/slice";
import fleet from "./fleet/slice";
import flightPlayer from "./flight_player/slice";
import liveVideo from "./live_video/slice";
import livekit from "./livekit/slice";
import marker from "./markers/slice";
import mission from "./mission/slice";
import pilotInteractables from "./pilot_interactables/slice";
import pilot from "./pilot/slice";
import relay from "./relay/slice";
import responsiveLayout from "./responsive_layout/slice";
import skillLayout from "./skill_layout/slice";
import teleop from "./teleop/slice";
import uiFeedback from "./ui_feedback/slice";
import vehicleSettings from "./vehicle_settings/slice";
import workflow from "./workflow/slice";

import type { AnyAction } from "@reduxjs/toolkit";
import type { History } from "history";

const createRootReducer = (history: History) => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    ar,
    auth: CLOUD_API.modules.auth.reducer,
    currentUser,
    dashboard,
    dataFiles: CLOUD_API.modules.dataFiles.reducer,
    downloadFiles,
    featureFlags: CLOUD_API.modules.featureFlags.reducer,
    fileKinds: CLOUD_API.modules.fileKinds.reducer,
    fileSelector,
    fleet,
    flightPlayer,
    livekit,
    liveVideo,
    marker,
    mission,
    pilot,
    flights: CLOUD_API.modules.flights.reducer,
    releases: CLOUD_API.modules.releases.reducer,
    skillLayout,
    teleop,
    users: CLOUD_API.modules.users.reducer,
    uconUploadConfig: CLOUD_API.modules.uconUploadConfig.reducer,
    pilotInteractables,
    vehicles: CLOUD_API.modules.vehicles.reducer,
    viewer,
    wifiSettings: CLOUD_API.modules.wifiSettings.reducer,
    uiFeedback,
    workflow,
    vehicleSettings,
    responsiveLayout,
    relay,
  });

  return (state: any, action: AnyAction) => {
    // Clear all data in redux store to initial on logout
    if (action.type === CLOUD_API.modules.auth.asyncThunks.authLogout.fulfilled.type) {
      state = undefined;
    }

    return appReducer(state, action);
  };
};

export default createRootReducer;
