import React from "react";

import { toast } from "@skydio/rivet-ui/src";

import { Environment } from "../../state/ui/types";
import { ErrorType } from "../../utils/errors";

import { ErrorIcon, InfoIcon, IconType, SuccessIcon, WarningIcon } from "./icons";
import { ClientErrorHelpFix, getClientErrorHelpMessage } from "./userMessaging";

/**
 * Ant Design Notification types.
 */
export enum NotificationType {
  ERROR = "error",
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
}

/**
 * Creates a notification that will auto-close after duration (seconds) or, when duration=0,
 * remain open until user closes it.
 */
export const createNotification = (
  description: JSX.Element | string,
  icon: IconType,
  notificationType: NotificationType,
  title: string,
  duration?: number
) =>
  toast[notificationType](title, {
    className: `${notificationType} viewer-notification`,
    description: description,
    duration: (duration ?? 10) * 1000,
    icon: icon,
    position: "top-right",
  });

/**
 * Creates an error notification.
 */
export const createErrorNotification = (
  description: JSX.Element | string,
  title: string,
  duration?: number
) => createNotification(description, <ErrorIcon />, NotificationType.ERROR, title, duration);

/**
 * Creates an info notification.
 */
export const createInfoNotification = (
  description: JSX.Element | string,
  title: string,
  duration?: number
) => createNotification(description, <InfoIcon />, NotificationType.INFO, title, duration);

/**
 * Creates a success notification.
 */
export const createSuccessNotification = (
  description: JSX.Element | string,
  title: string,
  duration?: number
) => createNotification(description, <SuccessIcon />, NotificationType.SUCCESS, title, duration);

/**
 * Creates a warning notification.
 */
export const createWarningNotification = (
  description: JSX.Element | string,
  title: string,
  duration?: number
) => createNotification(description, <WarningIcon />, NotificationType.WARNING, title, duration);

/**
 * Create error notification from fetch attempt.
 */
export const notifyOfFetchError = (
  error: any,
  title: string,
  environment: Environment,
  duration = 0
) => {
  // TODO(rachel): Errors from flight deck need improvement so that error names and messages
  // can be set. Searching both for substrings is best solution in the meantime.
  // TODO(rachel): Errors from cloud lack information and consistency. Name errors correctly.
  const errorString = `${error.name} ${error.message} ${error.code}`.toLowerCase();

  let fixes: Set<ClientErrorHelpFix> = new Set();
  if (
    errorString.includes(ErrorType.NO_PERMISSION_CODE) ||
    errorString.includes(ErrorType.NO_PERMISSION_MESSAGE)
  ) {
    fixes.add(ClientErrorHelpFix.CHECK_PERMISSIONS);
  } else if (errorString.includes(ErrorType.NOT_UPLOADED.toLowerCase())) {
    fixes.add(ClientErrorHelpFix.FINISH_UPLOAD);
  } else if (
    errorString.includes(ErrorType.SCANS_DIRECTORY_DOES_NOT_EXIST.toLowerCase()) ||
    errorString.includes(ErrorType.SCAN_DATAS_IS_EMPTY.toLowerCase())
  ) {
    fixes.add(ClientErrorHelpFix.CHECK_MEDIA_SD_CARD_CONTENTS);
  } else if (errorString.includes(ErrorType.SCAN_NOT_FOUND_IN_SCAN_DATAS.toLowerCase())) {
    fixes.add(ClientErrorHelpFix.CHECK_MEDIA_SD_CARD_CONTENTS);
  } else if (
    errorString.includes(ErrorType.TIMEOUT.toLowerCase()) ||
    errorString.includes(ErrorType.TOO_MANY_REQUESTS.toLowerCase()) ||
    errorString.includes(ErrorType.BUSY.toLowerCase())
  ) {
    fixes.add(ClientErrorHelpFix.WAIT);
  } else if (
    errorString.includes(ErrorType.NOT_FOUND.toLowerCase()) ||
    errorString.includes(ErrorType.NOT_FOUND_CLOUD.toLowerCase())
  ) {
    if (environment === Environment.CLOUD) {
      fixes.add(ClientErrorHelpFix.CHECK_CLOUD_FILE_EXISTENCE);
    } else if (environment === Environment.VEHICLE) {
      fixes.add(ClientErrorHelpFix.CHECK_MEDIA_SD_CARD_CONTENTS);
    }
    fixes.add(ClientErrorHelpFix.RELOAD_APP);
  } else {
    fixes.add(ClientErrorHelpFix.CHECK_CONNECTION);
    fixes.add(ClientErrorHelpFix.RELOAD_APP);
  }

  const description = getClientErrorHelpMessage(fixes);

  createErrorNotification(description, title, duration);
};
