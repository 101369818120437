/**
 * NOTE(sam): This is mostly a copy of the usePopover hook from @react-aria/popover with some small
 * modifications to more easily support Rivet popovers
 */

import { ariaHideOutside } from "@react-aria/overlays";
import { useEffect } from "react";
import { mergeProps, useOverlay, useOverlayPosition } from "react-aria";

import { toReactAriaPlacement } from "../../utils/overlay";

import type { RefObject } from "react";
import type { AriaOverlayProps, AriaPopoverProps, PopoverAria } from "react-aria";
import type { OverlayTriggerState } from "react-stately";
import type { OverlayPlacement } from "../../utils/overlay";

export interface Props {
  /**
   * Whether the element should render an arrow.
   * @default false
   */
  showArrow?: boolean;
  /**
   * The placement of the element with respect to its anchor element.
   * @default 'top'
   */
  placement?: OverlayPlacement;
  /**
   * A ref for the scrollable region within the overlay.
   * @default popoverRef
   */
  scrollRef?: RefObject<HTMLElement>;
}

export type ReactAriaPopoverProps = Props & Omit<AriaPopoverProps, "placement"> & AriaOverlayProps;

/**
 * Provides the behavior and accessibility implementation for a popover component.
 * A popover is an overlay element positioned relative to a trigger.
 */
export function useReactAriaPopover(
  props: ReactAriaPopoverProps,
  state: OverlayTriggerState
): PopoverAria {
  const {
    triggerRef,
    popoverRef,
    showArrow,
    offset = 7,
    crossOffset = 0,
    scrollRef,
    shouldFlip,
    boundaryElement,
    isDismissable = true,
    shouldCloseOnBlur = true,
    placement: placementProp = "top",
    containerPadding,
    shouldCloseOnInteractOutside,
    isNonModal: isNonModalProp,
    isKeyboardDismissDisabled,
    ...otherProps
  } = props;

  const isNonModal = isNonModalProp || true;

  const { overlayProps, underlayProps } = useOverlay(
    {
      isOpen: state.isOpen,
      onClose: state.close,
      shouldCloseOnBlur,
      isDismissable,
      isKeyboardDismissDisabled,
      shouldCloseOnInteractOutside: shouldCloseOnInteractOutside
        ? shouldCloseOnInteractOutside
        : element => {
            // Don't close if the click is within the trigger or the popover itself
            const trigger = triggerRef?.current;

            return !trigger || !trigger.contains(element);
          },
    },
    popoverRef
  );

  const {
    overlayProps: positionProps,
    arrowProps,
    placement,
  } = useOverlayPosition({
    ...otherProps,
    shouldFlip,
    crossOffset,
    targetRef: triggerRef,
    overlayRef: popoverRef,
    isOpen: state.isOpen,
    scrollRef,
    boundaryElement,
    containerPadding,
    placement: toReactAriaPlacement(placementProp),
    offset: showArrow ? offset + 3 : offset,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClose: () => {},
  });

  useEffect(() => {
    if (state.isOpen && !isNonModal && popoverRef.current) {
      return ariaHideOutside([popoverRef.current]);
    }
  }, [isNonModal, state.isOpen, popoverRef]);

  return {
    popoverProps: mergeProps(overlayProps, positionProps),
    arrowProps,
    underlayProps,
    placement,
  };
}
