import { LayoutGroup } from "framer-motion";
import { last } from "lodash";
import { forwardRef, useId, useMemo } from "react";
import { useTabList } from "react-aria";
import { useTabListState } from "react-stately";
import { tv } from "tailwind-variants";

import { useDomRef } from "../../hooks/useDomRef";
import { cn } from "../../utils/cn";
import { filterDOMProps } from "../../utils/filterDomProps";
import { dataFocusVisible } from "../../utils/tailwindClasses";
import { isBadgeContentEmpty } from "../badge/Badge";
import { Tab } from "./Tab";
import { isTabTitleBadge, TabItem } from "./TabItem";
import { TabPanel } from "./TabPanel";

import type { TabListStateOptions } from "@react-stately/tabs";
import type { CollectionChildren } from "@react-types/shared";
import type { ComponentPropsWithoutRef, ForwardedRef, ReactElement, ReactNode } from "react";
import type { AriaTabListProps } from "react-aria";
import type { VariantProps } from "tailwind-variants";
import type { CollectionProps } from "../../utils/collections";
import type { SlotsToClasses } from "../../utils/types";
import type { BadgeProps } from "../badge/Badge";

const tabsVariants = tv({
  slots: {
    base: "relative inline-flex",
    tabList: [
      "flex",
      "h-fit",
      "flex-nowrap",
      "items-center",
      "gap-2",
      "overflow-x-scroll",
      "scrollbar-hide",
    ],
    tab: [
      "z-0",
      "w-full",
      "flex",
      "group",
      "relative",
      "justify-center",
      "items-center",
      "outline-none",
      "border-none",
      "bg-transparent",
      "cursor-pointer",
      "transition-opacity",
      "tap-highlight-transparent",
      "data-[disabled=true]:cursor-not-allowed",
      "data-[disabled=true]:opacity-30",
      "data-[hover-unselected=true]:opacity-60",
      ...dataFocusVisible,
    ],
    cursor: "absolute z-0 bg-white",
    tabContent: [
      "relative",
      "z-10",
      "text-inherit",
      "whitespace-nowrap",
      "transition-colors",
      "text-gray-500",
      "dark:text-gray-400",
    ],
    panel: ["py-3", "px-1", "outline-none", "data-[inert=true]:hidden", ...dataFocusVisible],
    wrapper: "flex",
    endContentWrapper: "ml-auto",
    badge: "",
  },
  variants: {
    variant: {
      solid: {
        tab: "px-3 py-1",
        cursor: "inset-0",
        tabList: "bg-gray-50 p-1 dark:bg-gray-800",
      },
      underlined: {
        tab: "px-3 pb-2 pt-1",
        tabList: "bg-transparent dark:bg-transparent",
        cursor: "bottom-0 h-[2px] w-full shadow-[0_1px_0px_0_rgba(0,0,0,0.05)]",
        // No interior padding in this variant so we need to shove badges fully inside the tab list
        badge: "top-0 translate-y-0",
      },
    },
    size: {
      sm: {
        tabList: "rounded-md",
        tab: "rounded-sm text-xs",
        cursor: "rounded-sm",
      },
      md: {
        tabList: "rounded-md",
        tab: "rounded-md text-sm",
        cursor: "rounded-md",
      },
      lg: {
        tabList: "rounded-lg",
        tab: "rounded-md text-base",
        cursor: "rounded-md",
      },
    },
    color: {
      default: {},
      primary: {},
    },
    placement: {
      top: {
        wrapper: "flex-col",
      },
      bottom: {
        wrapper: "flex-col-reverse",
      },
      left: {
        tabList: "flex-col",
        panel: "px-3 py-0",
      },
      right: {
        tabList: "flex-col",
        panel: "px-3 py-0",
        wrapper: "flex-row-reverse",
      },
    },
    isDisabled: {
      true: {
        tabList: "pointer-events-none opacity-60",
      },
    },
    withDivider: {
      true: {},
      false: {},
    },
    badgeAtEnd: {
      none: {},
      dot: {
        tabList: "pr-1",
      },
      oneChar: {
        tabList: "pr-2",
      },
      multipleChars: {
        tabList: "pr-2.5",
      },
    },
  },
  compoundVariants: [
    {
      variant: "solid",
      size: "sm",
      className: {
        tab: "h-7",
      },
    },
    {
      variant: "solid",
      size: "md",
      className: {
        tab: "h-8",
      },
    },
    {
      variant: "solid",
      size: "lg",
      className: {
        tab: "h-9",
      },
    },
    {
      variant: "underlined",
      size: "sm",
      className: {
        tab: "h-9",
      },
    },
    {
      variant: "underlined",
      size: "md",
      className: {
        tab: "h-10",
      },
    },
    {
      variant: "underlined",
      size: "lg",
      className: {
        tab: "h-11",
      },
    },
    {
      variant: "solid",
      color: "default",
      className: {
        cursor: "bg-white shadow-sm dark:bg-gray-700",
        tabContent:
          "group-data-[selected=true]:text-gray-850 dark:group-data-[selected=true]:text-white",
      },
    },
    {
      variant: "solid",
      color: "primary",
      className: {
        cursor: "bg-blue text-white",
        tabContent: "group-data-[selected=true]:text-white",
      },
    },
    {
      variant: "underlined",
      color: "default",
      className: {
        cursor: "bg-gray-850 dark:bg-white",
        tabContent:
          "group-data-[selected=true]:text-gray-850 dark:group-data-[selected=true]:text-white",
      },
    },
    {
      variant: "underlined",
      color: "primary",
      className: {
        cursor: "bg-blue",
        tabContent: "group-data-[selected=true]:text-blue",
      },
    },
    {
      variant: "underlined",
      withDivider: true,
      className: {
        base: [
          "before:content-['']",
          "before:absolute",
          "before:right-0",
          "before:left-0",
          "before:bottom-0",
          "before:border-box",
          "before:border-solid",
          "before:border-b",
          "before:border-t-0",
          "before:border-l-0",
          "before:border-r-0",
          "before:border-gray-50",
          "dark:before:border-gray-800",
        ],
      },
    },
  ],
  compoundSlots: [
    {
      variant: "underlined",
      slots: ["tab", "tabList", "cursor"],
      className: "rounded-none",
    },
  ],
  defaultVariants: {
    variant: "solid",
    size: "md",
    color: "default",
    placement: "top",
    withDivider: true,
    badgeAtEnd: "none",
  },
});

export type TabsSlots = ReturnType<typeof tabsVariants>;
export type TabsClassNames = SlotsToClasses<keyof TabsSlots>;
export type TabsVariants = VariantProps<typeof tabsVariants>;

export interface TabsProps<T extends object>
  extends Omit<ComponentPropsWithoutRef<"div">, "children" | "color">,
    TabsVariants,
    Omit<TabListStateOptions<T>, "children" | keyof AriaTabListProps<T>>,
    Omit<AriaTabListProps<T>, "children" | "orientation" | "items">,
    CollectionProps<T> {
  classNames?: TabsClassNames;
  placement?: "top" | "bottom" | "left" | "right";
  destroyInactiveTabPanel?: boolean;
  endContent?: ReactNode;
}

const getBadgeContentSize = (node: ReactElement<BadgeProps>) => {
  const { content } = node.props;
  if (isBadgeContentEmpty(content)) {
    return "dot";
  }
  if (String(content).length === 1) {
    return "oneChar";
  }
  return "multipleChars";
};

function TabsImpl<T extends object>(
  {
    className,
    classNames,
    children,
    destroyInactiveTabPanel = true,
    placement = "top",
    variant,
    size,
    color,
    withDivider,
    isDisabled,
    endContent,
    ...props
  }: TabsProps<T>,
  ref: ForwardedRef<HTMLDivElement>
) {
  const domRef = useDomRef(ref);

  const state = useTabListState({
    children: children as CollectionChildren<T>,
    ...props,
  });

  const { tabListProps: ariaTabListProps } = useTabList<T>(props, state, domRef);

  // NOTE(sam): This is kind of a hilarious workaround to leave space for a badge at the end of the
  // tab list, otherwise the whole container would scroll because the badge would be clipped.
  const badgeAtEnd = useMemo(() => {
    const lastTab = last([...state.collection]);
    if (lastTab && isTabTitleBadge(lastTab.rendered)) {
      return getBadgeContentSize(lastTab.rendered);
    }
    return "none";
  }, [state.collection]);

  const slots = useMemo(
    () => tabsVariants({ className, variant, size, color, withDivider, isDisabled, badgeAtEnd }),
    [className, variant, size, color, withDivider, isDisabled, badgeAtEnd]
  );

  const baseProps = useMemo(
    () => ({
      "data-slot": "base",
      className: slots.base({ className: cn(classNames?.base, className) }),
      ...filterDOMProps(props),
    }),
    [slots, className, classNames?.base, props]
  );

  const wrapperProps = useMemo(
    () => ({
      "data-slot": "wrapper",
      "data-placement": placement,
      className: slots.wrapper({ className: classNames?.wrapper }),
    }),
    [slots, placement, classNames?.wrapper]
  );

  const tabListProps = useMemo(
    () => ({
      ref: domRef,
      "data-slot": "tabList",
      className: slots.tabList({ className: classNames?.tabList }),
      ...ariaTabListProps,
    }),
    [domRef, slots, classNames?.tabList, ariaTabListProps]
  );

  const layoutId = useId();

  return (
    <div {...wrapperProps}>
      <div {...baseProps}>
        <div {...tabListProps}>
          <LayoutGroup id={layoutId}>
            {[...state.collection].map(item => (
              <TabItem
                key={item.key}
                item={item}
                state={state}
                slots={slots}
                classNames={classNames}
                listRef={domRef}
                isDisabled={isDisabled}
                {...item.props}
              />
            ))}
          </LayoutGroup>
        </div>
        {endContent ? (
          <div
            data-slot="endContent"
            className={slots.endContentWrapper({ className: classNames?.endContentWrapper })}
          >
            {endContent}
          </div>
        ) : null}
      </div>
      {[...state.collection].map(item => (
        <TabPanel
          key={item.key}
          tabKey={item.key}
          state={state}
          slots={slots}
          classNames={classNames}
          destroyInactiveTabPanel={destroyInactiveTabPanel}
        />
      ))}
    </div>
  );
}

const WrappedTabs = forwardRef(TabsImpl) as <T extends object>(
  props: TabsProps<T> & { ref?: ForwardedRef<HTMLDivElement> }
) => ReactElement;

export const Tabs = WrappedTabs as typeof WrappedTabs & {
  displayName: "Tabs";
  Tab: typeof Tab;
};
Tabs.displayName = "Tabs";
Tabs.Tab = Tab;
