import { findKey } from "lodash";

import alert_v2_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/alert_v2_pb";
import type { ValueType } from "@skydio/core";

export const getTriggerTypeKey = (triggerType: ValueType<alert_v2_pb.Trigger.TypeEnumMap>) =>
  findKey(alert_v2_pb.Trigger.TypeEnum, key => key === triggerType);

export const getTriggerTypes = () => {
  const triggerTypes = [];
  for (const type in alert_v2_pb.Trigger.TypeEnum) {
    triggerTypes.push(type);
  }

  return triggerTypes;
};
