import { AnimatePresence } from "framer-motion";
import { forwardRef, useState } from "react";
import { Overlay } from "react-aria";

import { Button } from "../button/Button";
import { ModalProvider } from "./context";
import { ModalBody } from "./ModalBody";
import { ModalContent } from "./ModalContent";
import { ModalDescription } from "./ModalDescription";
import { ModalFooter } from "./ModalFooter";
import { ModalHeader } from "./ModalHeader";
import { ModalTitle } from "./ModalTitle";
import { useModal } from "./useModal";

import type { FC, ReactNode } from "react";
import type { ForwardRefComponentWithSubcomponents } from "../../utils/types";
import type { ButtonProps } from "../button/Button";
import type { UseModalProps } from "./useModal";

export type ModalProps = UseModalProps;

export const Modal = forwardRef<HTMLDivElement, UseModalProps>(({ children, ...props }, ref) => {
  const context = useModal(props, ref);

  const overlay = <Overlay portalContainer={context.portalContainer}>{children}</Overlay>;

  return (
    <ModalProvider value={context}>
      <AnimatePresence>{context.isOpen ? overlay : null}</AnimatePresence>
    </ModalProvider>
  );
}) as ForwardRefComponentWithSubcomponents<
  HTMLDivElement,
  {
    Content: typeof ModalContent;
    Body: typeof ModalBody;
    Header: typeof ModalHeader;
    Footer: typeof ModalFooter;
    Title: typeof ModalTitle;
    Description: typeof ModalDescription;
    Confirmation: typeof ConfirmationModal;
    useConfirmationModal: typeof useConfirmationModal;
  },
  ModalProps
>;
Modal.displayName = "Modal";
Modal.Content = ModalContent;
Modal.Body = ModalBody;
Modal.Header = ModalHeader;
Modal.Footer = ModalFooter;
Modal.Title = ModalTitle;
Modal.Description = ModalDescription;

/** Putting confirmation modal in the same file to avoid circular import */

export interface ConfirmationModalProps extends Omit<ModalProps, "title"> {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onConfirm: () => void;
  title?: ReactNode;
  subtitle?: ReactNode;
  confirmText: string;
  cancelText?: string;
  cancelButtonProps?: ButtonProps;
  confirmButtonProps?: ButtonProps;
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  isOpen,
  onOpenChange,
  onConfirm,
  title,
  subtitle,
  confirmText,
  cancelText = "Cancel",
  cancelButtonProps,
  confirmButtonProps,
  ...props
}) => (
  <Modal
    isOpen={isOpen}
    onOpenChange={onOpenChange}
    isDismissable={false}
    isKeyboardDismissDisabled
    hideCloseButton
    placement="top"
    {...props}
  >
    <Modal.Content>
      <Modal.Header>
        {title ? <Modal.Title>{title}</Modal.Title> : null}
        {subtitle ? <Modal.Description>{subtitle}</Modal.Description> : null}
      </Modal.Header>
      <Modal.Footer>
        <Button variant="light" {...cancelButtonProps} onClick={() => onOpenChange(false)}>
          {cancelText}
        </Button>
        <Button
          variant="primary"
          {...confirmButtonProps}
          onClick={() => {
            onOpenChange(false);
            onConfirm();
          }}
        >
          {confirmText}
        </Button>
      </Modal.Footer>
    </Modal.Content>
  </Modal>
);
ConfirmationModal.displayName = "Modal.Confirmation";
Modal.Confirmation = ConfirmationModal;

type UseConfirmationModalProps = Omit<ConfirmationModalProps, "isOpen" | "onOpenChange">;

const useConfirmationModal = (props: UseConfirmationModalProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const showConfirmation = () => setIsOpen(true);

  const confirmationModalProps: ConfirmationModalProps = {
    ...props,
    isOpen,
    onOpenChange: setIsOpen,
  };

  return {
    confirmationModalProps,
    showConfirmation,
  };
};
Modal.useConfirmationModal = useConfirmationModal;
