import { env } from "next-runtime-env";

import {
  isDeployedCloudApi,
  LOCAL_CLOUD_API_URL,
} from "@skydio/api_util/src/backends/cloud_api/requests-common";

export const ENV = env("NEXT_PUBLIC_ENV") ?? "development";
export const IS_DEV = ENV === "development";

export const CLOUD_API_URL = env("NEXT_PUBLIC_BACKEND_URL") ?? LOCAL_CLOUD_API_URL;
export const CLOUD_API_WS_URL = CLOUD_API_URL.replace("http", "ws");
export const CLOUD_API_DEPLOYED = isDeployedCloudApi(CLOUD_API_URL);

export const DISABLE_GEOCODING = env("NEXT_PUBLIC_DISABLE_GEOCODING") == "true";
export const DISABLE_AR_STREET_OVERLAYS = env("NEXT_PUBLIC_DISABLE_AR_STREET_OVERLAYS") == "true";

export const ROOT_DOMAIN = env("NEXT_PUBLIC_ROOT_DOMAIN");
