/**
 * Temporary redux slice for markers to be replaced by the markers store and worker.
 * This slice is used to store the upsert timestamp we want to filter markers by.
 * Any markers that are upserted after this timestamp will be shown on the map.
 *
 * This will be used by the useOrganizationMarkersWithSearch hook to filter markers by the upsert timestamp.
 */

import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";

export interface MarkersState {
  markerUpsertedAfterTime: string | null;
}

const initialState: MarkersState = {
  markerUpsertedAfterTime: null,
};

const { reducer, actions: markerActions } = createSlice({
  name: "markers",
  initialState,
  reducers: {
    setMarkerUpsertedAfterTime(state, action: PayloadAction<string>) {
      state.markerUpsertedAfterTime = action.payload;
    },
    unsetMarkerUpsertedAfterTime(state) {
      state.markerUpsertedAfterTime = null;
    },
  },
});

export default reducer;
export { markerActions };
