"use client";

import Geocoding from "@mapbox/mapbox-sdk/services/geocoding";
import { createContext, useContext, useRef } from "react";

import { DISABLE_GEOCODING } from "./env";

import type { GeocodeService } from "@mapbox/mapbox-sdk/services/geocoding";
import type { ReactNode } from "react";

interface MapboxContext {
  accessToken: string;
  baseApiUrl: string;
  geocodeService: GeocodeService | null;
}

export const mapboxContext = createContext<MapboxContext | null>(null);

const useMapboxContext = () => {
  const context = useContext(mapboxContext);

  if (context === null) {
    throw new Error("useMapboxContext must be used within a MapboxProvider");
  }
  return context;
};

export const useMapboxToken = () => {
  const context = useMapboxContext();
  return context.accessToken;
};

export const useMapboxHost = () => {
  const context = useMapboxContext();
  return context.baseApiUrl;
};

export const useStandardMapboxStyles = () => {
  const context = useMapboxContext();
  return !context.baseApiUrl.includes("api.mapbox.com");
};

export const useGeocodeService = () => {
  const context = useMapboxContext();
  // React does not allow you to conditionally call hooks, so anything using this hook
  // with geocoding disabled should just get undefined instead.
  if (DISABLE_GEOCODING) return;

  return context.geocodeService;
};

export default function MapboxProvider({
  accessToken,
  host,
  children,
}: {
  accessToken: string;
  host: string;
  children: ReactNode;
}) {
  const geocodeServiceRef = useRef<GeocodeService | null>();

  if (geocodeServiceRef.current === undefined) {
    // Only init geocode service if mapbox token is non-empty
    geocodeServiceRef.current = accessToken ? Geocoding({ accessToken, origin: host }) : null;
  }

  return (
    <mapboxContext.Provider
      value={{ accessToken, geocodeService: geocodeServiceRef.current, baseApiUrl: host }}
    >
      {children}
    </mapboxContext.Provider>
  );
}
