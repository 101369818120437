import { motion } from "framer-motion";
import { forwardRef, useMemo, useRef } from "react";
import { DismissButton, mergeProps, useDialog } from "react-aria";
import { RemoveScroll } from "react-remove-scroll";

import { getTransformOrigins } from "../../utils/overlay";
import { fade, scaleSpringOpacity } from "../../utils/transitionVariants";
import { usePopoverContext } from "./context";

import type { HTMLMotionProps } from "framer-motion";
import type { DOMAttributes, HTMLAttributes, ReactNode } from "react";
import type { AriaDialogProps } from "react-aria";

export interface PopoverContentProps
  extends AriaDialogProps,
    Omit<HTMLAttributes<HTMLDivElement>, "children" | "role"> {
  children: ReactNode | ((titleProps: DOMAttributes<HTMLElement>) => ReactNode);
}

export const PopoverContent = forwardRef<HTMLDivElement, PopoverContentProps>((props, _) => {
  const { children, className, ...otherProps } = props;

  const {
    isOpen,
    placement,
    motionProps,
    backdrop,
    disableAnimation,
    shouldBlockScroll,
    popoverProps,
    dialogProps,
    backdropProps,
    contentProps,
    isNonModal,
    onClose,
  } = usePopoverContext();

  const dialogRef = useRef(null);
  const { dialogProps: ariaDialogProps, titleProps } = useDialog({}, dialogRef);

  // Not needed in the popover context, the popover role comes from popoverProps
  delete ariaDialogProps.role;

  const content = (
    <>
      {!isNonModal && <DismissButton onDismiss={onClose} />}
      <div {...mergeProps(ariaDialogProps, dialogProps, otherProps)} ref={dialogRef}>
        <div {...mergeProps(contentProps, { className })}>
          {typeof children === "function" ? children(titleProps) : children}
        </div>
      </div>
      <DismissButton onDismiss={onClose} />
    </>
  );

  const backdropContent = useMemo(() => {
    if (backdrop === "transparent") {
      return null;
    }

    if (disableAnimation) {
      return <div {...backdropProps} />;
    }

    return (
      <motion.div
        animate="enter"
        exit="exit"
        initial="exit"
        variants={fade}
        {...(backdropProps as HTMLMotionProps<"div">)}
      />
    );
  }, [backdrop, disableAnimation, backdropProps]);

  return (
    <div {...popoverProps}>
      {backdropContent}
      <RemoveScroll forwardProps enabled={shouldBlockScroll && isOpen} removeScrollBar={false}>
        {disableAnimation ? (
          content
        ) : (
          <motion.div
            animate="enter"
            exit="exit"
            initial="initial"
            style={{
              ...getTransformOrigins(placement === "center" ? "top" : placement),
            }}
            variants={scaleSpringOpacity}
            {...motionProps}
          >
            {content}
          </motion.div>
        )}
      </RemoveScroll>
    </div>
  );
});

PopoverContent.displayName = "Popover.Content";
