// source: pbtypes/tools/cloud_api/vehicle.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.object.extend(proto, google_protobuf_field_mask_pb);
var pbtypes_vehicle_dock_client_dock_client_pb = require('../../../pbtypes/vehicle/dock_client/dock_client_pb.js');
goog.object.extend(proto, pbtypes_vehicle_dock_client_dock_client_pb);
var pbtypes_tools_cloud_api_enums_pb = require('../../../pbtypes/tools/cloud_api/enums_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_enums_pb);
var pbtypes_tools_cloud_api_release_pb = require('../../../pbtypes/tools/cloud_api/release_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_release_pb);
var pbtypes_tools_cloud_api_pagination_pb = require('../../../pbtypes/tools/cloud_api/pagination_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_pagination_pb);
var pbtypes_tools_cloud_api_user_pb = require('../../../pbtypes/tools/cloud_api/user_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_user_pb);
goog.exportSymbol('proto.cloud_api.UpdateVehicleRequest', null, global);
goog.exportSymbol('proto.cloud_api.Vehicle', null, global);
goog.exportSymbol('proto.cloud_api.Vehicles', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.Vehicle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.Vehicle.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.Vehicle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.Vehicle.displayName = 'proto.cloud_api.Vehicle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.Vehicles = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.Vehicles.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.Vehicles, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.Vehicles.displayName = 'proto.cloud_api.Vehicles';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.UpdateVehicleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.UpdateVehicleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.UpdateVehicleRequest.displayName = 'proto.cloud_api.UpdateVehicleRequest';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.Vehicle.repeatedFields_ = [9,23];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.Vehicle.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.Vehicle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.Vehicle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Vehicle.toObject = function(includeInstance, msg) {
  var f, obj = {
vehicleId: jspb.Message.getFieldWithDefault(msg, 1, ""),
vehicleType: jspb.Message.getFieldWithDefault(msg, 2, 0),
skydioSerial: jspb.Message.getFieldWithDefault(msg, 3, ""),
disabled: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
release: (f = msg.getRelease()) && pbtypes_tools_cloud_api_release_pb.ReleaseFile.toObject(includeInstance, f),
userEmailsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
usersList: jspb.Message.toObjectList(msg.getUsersList(),
    pbtypes_tools_cloud_api_user_pb.RelatedUser.toObject, includeInstance),
organizationId: jspb.Message.getFieldWithDefault(msg, 10, ""),
organizationFlightCount: jspb.Message.getFieldWithDefault(msg, 8, 0),
teleopEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
linkedDockId: jspb.Message.getFieldWithDefault(msg, 12, ""),
linkedAccessPointSerial: jspb.Message.getFieldWithDefault(msg, 22, ""),
releaseOverride: (f = msg.getReleaseOverride()) && pbtypes_tools_cloud_api_release_pb.DeviceReleaseOverride.toObject(includeInstance, f),
scheduledMissionsDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
remoteIdExempt: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
uuid: jspb.Message.getFieldWithDefault(msg, 16, ""),
displayName: jspb.Message.getFieldWithDefault(msg, 17, ""),
linkedDockType: jspb.Message.getFieldWithDefault(msg, 18, 0),
imei: jspb.Message.getFieldWithDefault(msg, 19, ""),
iccid: jspb.Message.getFieldWithDefault(msg, 20, ""),
isSimulator: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
linkedSiteId: jspb.Message.getFieldWithDefault(msg, 24, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.Vehicle}
 */
proto.cloud_api.Vehicle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.Vehicle;
  return proto.cloud_api.Vehicle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.Vehicle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.Vehicle}
 */
proto.cloud_api.Vehicle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 2:
      var value = /** @type {!proto.cloud_api.VehicleType.VehicleTypeEnum} */ (reader.readEnum());
      msg.setVehicleType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkydioSerial(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisabled(value);
      break;
    case 5:
      var value = new pbtypes_tools_cloud_api_release_pb.ReleaseFile;
      reader.readMessage(value,pbtypes_tools_cloud_api_release_pb.ReleaseFile.deserializeBinaryFromReader);
      msg.setRelease(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserEmails(value);
      break;
    case 23:
      var value = new pbtypes_tools_cloud_api_user_pb.RelatedUser;
      reader.readMessage(value,pbtypes_tools_cloud_api_user_pb.RelatedUser.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrganizationFlightCount(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTeleopEnabled(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkedDockId(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkedAccessPointSerial(value);
      break;
    case 13:
      var value = new pbtypes_tools_cloud_api_release_pb.DeviceReleaseOverride;
      reader.readMessage(value,pbtypes_tools_cloud_api_release_pb.DeviceReleaseOverride.deserializeBinaryFromReader);
      msg.setReleaseOverride(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setScheduledMissionsDisabled(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRemoteIdExempt(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 18:
      var value = /** @type {!proto.dock_client.DockVehiclePairing.DockType} */ (reader.readEnum());
      msg.setLinkedDockType(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setImei(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setIccid(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSimulator(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkedSiteId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.Vehicle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.Vehicle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.Vehicle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Vehicle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSkydioSerial();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDisabled();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getRelease();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      pbtypes_tools_cloud_api_release_pb.ReleaseFile.serializeBinaryToWriter
    );
  }
  f = message.getUserEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      pbtypes_tools_cloud_api_user_pb.RelatedUser.serializeBinaryToWriter
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getOrganizationFlightCount();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getTeleopEnabled();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getLinkedDockId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLinkedAccessPointSerial();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getReleaseOverride();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      pbtypes_tools_cloud_api_release_pb.DeviceReleaseOverride.serializeBinaryToWriter
    );
  }
  f = message.getScheduledMissionsDisabled();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getRemoteIdExempt();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getLinkedDockType();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getImei();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getIccid();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getIsSimulator();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getLinkedSiteId();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
};


/**
 * optional string vehicle_id = 1;
 * @return {string}
 */
proto.cloud_api.Vehicle.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Vehicle} returns this
 */
proto.cloud_api.Vehicle.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional VehicleType.VehicleTypeEnum vehicle_type = 2;
 * @return {!proto.cloud_api.VehicleType.VehicleTypeEnum}
 */
proto.cloud_api.Vehicle.prototype.getVehicleType = function() {
  return /** @type {!proto.cloud_api.VehicleType.VehicleTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.cloud_api.VehicleType.VehicleTypeEnum} value
 * @return {!proto.cloud_api.Vehicle} returns this
 */
proto.cloud_api.Vehicle.prototype.setVehicleType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string skydio_serial = 3;
 * @return {string}
 */
proto.cloud_api.Vehicle.prototype.getSkydioSerial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Vehicle} returns this
 */
proto.cloud_api.Vehicle.prototype.setSkydioSerial = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool disabled = 4;
 * @return {boolean}
 */
proto.cloud_api.Vehicle.prototype.getDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.Vehicle} returns this
 */
proto.cloud_api.Vehicle.prototype.setDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional ReleaseFile release = 5;
 * @return {?proto.cloud_api.ReleaseFile}
 */
proto.cloud_api.Vehicle.prototype.getRelease = function() {
  return /** @type{?proto.cloud_api.ReleaseFile} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_release_pb.ReleaseFile, 5));
};


/**
 * @param {?proto.cloud_api.ReleaseFile|undefined} value
 * @return {!proto.cloud_api.Vehicle} returns this
*/
proto.cloud_api.Vehicle.prototype.setRelease = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.Vehicle} returns this
 */
proto.cloud_api.Vehicle.prototype.clearRelease = function() {
  return this.setRelease(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.Vehicle.prototype.hasRelease = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated string user_emails = 9;
 * @return {!Array<string>}
 */
proto.cloud_api.Vehicle.prototype.getUserEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cloud_api.Vehicle} returns this
 */
proto.cloud_api.Vehicle.prototype.setUserEmailsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.Vehicle} returns this
 */
proto.cloud_api.Vehicle.prototype.addUserEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.Vehicle} returns this
 */
proto.cloud_api.Vehicle.prototype.clearUserEmailsList = function() {
  return this.setUserEmailsList([]);
};


/**
 * repeated RelatedUser users = 23;
 * @return {!Array<!proto.cloud_api.RelatedUser>}
 */
proto.cloud_api.Vehicle.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.cloud_api.RelatedUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_tools_cloud_api_user_pb.RelatedUser, 23));
};


/**
 * @param {!Array<!proto.cloud_api.RelatedUser>} value
 * @return {!proto.cloud_api.Vehicle} returns this
*/
proto.cloud_api.Vehicle.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.cloud_api.RelatedUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.RelatedUser}
 */
proto.cloud_api.Vehicle.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.cloud_api.RelatedUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.Vehicle} returns this
 */
proto.cloud_api.Vehicle.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional string organization_id = 10;
 * @return {string}
 */
proto.cloud_api.Vehicle.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Vehicle} returns this
 */
proto.cloud_api.Vehicle.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 organization_flight_count = 8;
 * @return {number}
 */
proto.cloud_api.Vehicle.prototype.getOrganizationFlightCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.Vehicle} returns this
 */
proto.cloud_api.Vehicle.prototype.setOrganizationFlightCount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool teleop_enabled = 11;
 * @return {boolean}
 */
proto.cloud_api.Vehicle.prototype.getTeleopEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.Vehicle} returns this
 */
proto.cloud_api.Vehicle.prototype.setTeleopEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string linked_dock_id = 12;
 * @return {string}
 */
proto.cloud_api.Vehicle.prototype.getLinkedDockId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Vehicle} returns this
 */
proto.cloud_api.Vehicle.prototype.setLinkedDockId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string linked_access_point_serial = 22;
 * @return {string}
 */
proto.cloud_api.Vehicle.prototype.getLinkedAccessPointSerial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Vehicle} returns this
 */
proto.cloud_api.Vehicle.prototype.setLinkedAccessPointSerial = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional DeviceReleaseOverride release_override = 13;
 * @return {?proto.cloud_api.DeviceReleaseOverride}
 */
proto.cloud_api.Vehicle.prototype.getReleaseOverride = function() {
  return /** @type{?proto.cloud_api.DeviceReleaseOverride} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_release_pb.DeviceReleaseOverride, 13));
};


/**
 * @param {?proto.cloud_api.DeviceReleaseOverride|undefined} value
 * @return {!proto.cloud_api.Vehicle} returns this
*/
proto.cloud_api.Vehicle.prototype.setReleaseOverride = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.Vehicle} returns this
 */
proto.cloud_api.Vehicle.prototype.clearReleaseOverride = function() {
  return this.setReleaseOverride(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.Vehicle.prototype.hasReleaseOverride = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional bool scheduled_missions_disabled = 14;
 * @return {boolean}
 */
proto.cloud_api.Vehicle.prototype.getScheduledMissionsDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.Vehicle} returns this
 */
proto.cloud_api.Vehicle.prototype.setScheduledMissionsDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool remote_id_exempt = 15;
 * @return {boolean}
 */
proto.cloud_api.Vehicle.prototype.getRemoteIdExempt = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.Vehicle} returns this
 */
proto.cloud_api.Vehicle.prototype.setRemoteIdExempt = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional string uuid = 16;
 * @return {string}
 */
proto.cloud_api.Vehicle.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Vehicle} returns this
 */
proto.cloud_api.Vehicle.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string display_name = 17;
 * @return {string}
 */
proto.cloud_api.Vehicle.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Vehicle} returns this
 */
proto.cloud_api.Vehicle.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional dock_client.DockVehiclePairing.DockType linked_dock_type = 18;
 * @return {!proto.dock_client.DockVehiclePairing.DockType}
 */
proto.cloud_api.Vehicle.prototype.getLinkedDockType = function() {
  return /** @type {!proto.dock_client.DockVehiclePairing.DockType} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.dock_client.DockVehiclePairing.DockType} value
 * @return {!proto.cloud_api.Vehicle} returns this
 */
proto.cloud_api.Vehicle.prototype.setLinkedDockType = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional string imei = 19;
 * @return {string}
 */
proto.cloud_api.Vehicle.prototype.getImei = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Vehicle} returns this
 */
proto.cloud_api.Vehicle.prototype.setImei = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string iccid = 20;
 * @return {string}
 */
proto.cloud_api.Vehicle.prototype.getIccid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Vehicle} returns this
 */
proto.cloud_api.Vehicle.prototype.setIccid = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional bool is_simulator = 21;
 * @return {boolean}
 */
proto.cloud_api.Vehicle.prototype.getIsSimulator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.Vehicle} returns this
 */
proto.cloud_api.Vehicle.prototype.setIsSimulator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional string linked_site_id = 24;
 * @return {string}
 */
proto.cloud_api.Vehicle.prototype.getLinkedSiteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Vehicle} returns this
 */
proto.cloud_api.Vehicle.prototype.setLinkedSiteId = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.Vehicles.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.Vehicles.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.Vehicles.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.Vehicles} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Vehicles.toObject = function(includeInstance, msg) {
  var f, obj = {
vehiclesList: jspb.Message.toObjectList(msg.getVehiclesList(),
    proto.cloud_api.Vehicle.toObject, includeInstance),
pagination: (f = msg.getPagination()) && pbtypes_tools_cloud_api_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.Vehicles}
 */
proto.cloud_api.Vehicles.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.Vehicles;
  return proto.cloud_api.Vehicles.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.Vehicles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.Vehicles}
 */
proto.cloud_api.Vehicles.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.Vehicle;
      reader.readMessage(value,proto.cloud_api.Vehicle.deserializeBinaryFromReader);
      msg.addVehicles(value);
      break;
    case 2:
      var value = new pbtypes_tools_cloud_api_pagination_pb.Pagination;
      reader.readMessage(value,pbtypes_tools_cloud_api_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.Vehicles.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.Vehicles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.Vehicles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Vehicles.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehiclesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cloud_api.Vehicle.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_tools_cloud_api_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Vehicle vehicles = 1;
 * @return {!Array<!proto.cloud_api.Vehicle>}
 */
proto.cloud_api.Vehicles.prototype.getVehiclesList = function() {
  return /** @type{!Array<!proto.cloud_api.Vehicle>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.Vehicle, 1));
};


/**
 * @param {!Array<!proto.cloud_api.Vehicle>} value
 * @return {!proto.cloud_api.Vehicles} returns this
*/
proto.cloud_api.Vehicles.prototype.setVehiclesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cloud_api.Vehicle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.Vehicle}
 */
proto.cloud_api.Vehicles.prototype.addVehicles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cloud_api.Vehicle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.Vehicles} returns this
 */
proto.cloud_api.Vehicles.prototype.clearVehiclesList = function() {
  return this.setVehiclesList([]);
};


/**
 * optional Pagination pagination = 2;
 * @return {?proto.cloud_api.Pagination}
 */
proto.cloud_api.Vehicles.prototype.getPagination = function() {
  return /** @type{?proto.cloud_api.Pagination} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_pagination_pb.Pagination, 2));
};


/**
 * @param {?proto.cloud_api.Pagination|undefined} value
 * @return {!proto.cloud_api.Vehicles} returns this
*/
proto.cloud_api.Vehicles.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.Vehicles} returns this
 */
proto.cloud_api.Vehicles.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.Vehicles.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.UpdateVehicleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.UpdateVehicleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.UpdateVehicleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UpdateVehicleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
vehicle: (f = msg.getVehicle()) && proto.cloud_api.Vehicle.toObject(includeInstance, f),
updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.UpdateVehicleRequest}
 */
proto.cloud_api.UpdateVehicleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.UpdateVehicleRequest;
  return proto.cloud_api.UpdateVehicleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.UpdateVehicleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.UpdateVehicleRequest}
 */
proto.cloud_api.UpdateVehicleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.Vehicle;
      reader.readMessage(value,proto.cloud_api.Vehicle.deserializeBinaryFromReader);
      msg.setVehicle(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.UpdateVehicleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.UpdateVehicleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.UpdateVehicleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UpdateVehicleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicle();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cloud_api.Vehicle.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional Vehicle vehicle = 1;
 * @return {?proto.cloud_api.Vehicle}
 */
proto.cloud_api.UpdateVehicleRequest.prototype.getVehicle = function() {
  return /** @type{?proto.cloud_api.Vehicle} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.Vehicle, 1));
};


/**
 * @param {?proto.cloud_api.Vehicle|undefined} value
 * @return {!proto.cloud_api.UpdateVehicleRequest} returns this
*/
proto.cloud_api.UpdateVehicleRequest.prototype.setVehicle = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.UpdateVehicleRequest} returns this
 */
proto.cloud_api.UpdateVehicleRequest.prototype.clearVehicle = function() {
  return this.setVehicle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.UpdateVehicleRequest.prototype.hasVehicle = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.FieldMask update_mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.cloud_api.UpdateVehicleRequest.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.cloud_api.UpdateVehicleRequest} returns this
*/
proto.cloud_api.UpdateVehicleRequest.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.UpdateVehicleRequest} returns this
 */
proto.cloud_api.UpdateVehicleRequest.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.UpdateVehicleRequest.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.cloud_api);
